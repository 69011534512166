.join-us {
    padding-top: 9em;
    .tools {
        margin-bottom: 20px;
        margin-left: 20px;
    }
    .background-square {
        width: 50px;
        height: 50px;
    }
}
