.background-square {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 1px;
        height: 30px;
        width: 30px;
        transform: rotate(45deg);
        border-radius: 20%;
        background: $color-3--6;
        z-index: -1;
    }
}

.list-square {
    background-color: $color-white;
    display: inline-block;
    padding: 5px 5px;
    position: absolute;
    top: 3em;
    left: 50%;
    .square-1, .square-2, .square-3 {
        display: inline-block;
        height: 8px;
        width: 8px;
        transform: rotate(45deg);
        border-radius: 20%;
        margin-right: 5px;
    }
    .square-1 {
        margin-left: 5px;
        background-color: $color-5--1;
    }
    .square-2 {
        background-color: $color-5--1;
    }
    .square-3 {
        background-color: $color-5--1;
    }
}


.shadow__wrapper {
    position: relative;
    &:after, &:before {
        content: "";
        position: absolute;
        width: 50%;
        height: 6px;
        z-index: 2;
    }
    &:after {
        bottom: 23px;
        right: 10px;
        transform: rotate(1deg);
        box-shadow: 0px 23px 17px $color-3--3;
    }
    &:before {
        bottom: 23px;
        left: 10px;
        transform: rotate(-1deg);
        box-shadow: 0px 23px 17px $color-3--3;
    }
}
