.flash-info {
    height: auto;
    background-image: inherit;
    .flash-info__wrapper {
        display: block;
        position: relative;
        text-align: center;
        top: 12%;
    }
    .flash-infos-wrap {
        display: block;
        position: relative;
        left: inherit;
        transform: none;
        top: inherit;
        max-width: inherit;
    }
    .flash-info__picture {
        text-align: center;
        margin-bottom: 20px;
    }
    .flash-infos__wrap-content {
        text-align: center;
        max-width: inherit;
    }
    .button-2 {
        float: inherit;
        left: 50%;
        height: 50px;
        width: 50px;
        margin-top: 30px;
    }
}
