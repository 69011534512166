// @name Search box
// @description Search module
.home {
    .search-box {
        flex-basis: 7%;
    }
}
.search-box {
    flex-basis: 6%;
    margin-top: -30px;
    margin-right: -10px;
    //z-index: 2;
    height: 100px;
    margin-bottom: -10px;
    overflow: hidden;
    form {
        margin: 0;
    }
    label {
        margin-right: em(0.5, 1.8);
        white-space: nowrap;
        font-size: 1.8em;
        font-family: $typo-1;
        text-transform: uppercase;
        input {
            margin-top: 0;
        }
    }

    .search-box__fields-wrapper, .search-box__button-wrapper {
        display: inline-block;
        vertical-align: bottom;
    }

    // @name Search Field wrapper
    // @state .search__field-wrapper--in-line - Indicates label and field are side by side.
    .search-box__field-wrapper {
        &.search-box__field-wrapper--in-line {
            display: table;
            width: 100%;
            label {
                display: table-cell;
                vertical-align: middle;
                text-align: right;
                padding-right: 1em;
            }
            input, select {
                display: table-cell;
                vertical-align: middle;
                width: 100%;
            }
        }
    }
    .button-1 {
        background: transparent;
        position: relative;
        height: 80px;
        width: 80px;
        top: 10px;
        overflow: visible;
        svg {
            fill: $color-black;
            width: 1.6em;
            height: 1.6em;
            position: absolute;
            right: 31%;
            top: 39%;
        }
        &:after {
            content: '';
            position: absolute;
            top: -26px;
            right: -64px;
            height: 130px;
            width: 130px;
            transform: rotate(45deg);
            border-radius: 25%;
            background: $color-5--1;
            z-index: -1;
            transition: background ease .3s;
        }
        &:hover, &:focus {
            &:after {
                background: $color-5--3;
                transition: background ease .5s;

            }
        }
    }
    .text-help {
        font-family: $typo-2;
        position: absolute;
        transition: .1s top;
        top: 4px;
        left: 138px;
        z-index: -1;
        font-style: italic;
        font-family: $typo-2;
        font-size: 0.8em;
        text-transform: none;
    }
    .input-text:focus + .text-help, .text-help:focus {
        top: -30px;
        transition: .2s top;
    }
    // Drop down menu
    .ddm {
        position: static;
        > .ddm__sub-level {
            top: 0;
            right: 3em;
            left: 0;
            width: 100%;
           // background-color: $color-5--1;
            background-color: $color-white;
            height: 101px;
            margin-top: 30px;
            text-align: center;
            input {
                width: 250px;
                border: 1px solid $color-black;
                background: transparent;
            }
            .search-box__field-wrapper {
                margin-top: 30px;
                position: relative;
            }
            .search-box__button-wrapper {
                .button-1 {
                    height: 50px;
                    width: 50px;
                    margin-left: 20px;
                    top: 0;
                    &:hover, &:focus {
                        &:after {
                            background-color: $color-5--3;
                            transition: background ease .3s;
                        }
                    }
                    &:after {
                        top: 0;
                        right: -1px;
                        height: 60px;
                        width: 60px;
                        background-color: $color-5--1;
                        transition: background ease .3s;
                    }
                }
            }
            .button-2 {
                border-radius: 20%;
                transform: rotate(45deg);
                float: right;
                top: 30px;
                right: 70px;
                background-color: $color-5--1;
                transition: background ease .3s;
                &:hover, &:focus {
                    background-color: $color-5--3;
                    transition: background ease .3s;
                }
                &:before {
                    left: 15px;
                    top: 24px;
                    font-size: 1.1em;
                    content: '\e002';
                    transform: rotate(-45deg) translateY(-50%) translateX(-50%);
                    color: $color-black;
                }
            }
        }
    }
}

// @name Search list
.pertinence {
    font-size: 1.2em;
    span {
        font-size: em(1, 1.2);
    }
}

@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, .pertinence span {
        font-size: 1.1em;
        font-weight: $bold;
    }
}

meter {
    -webkit-appearance: meter;
    -moz-appearance: meterbar;
    width: em(6, 1.2);
    height: em(1, 1.2);
    border: 1px solid darken($color-3--1, 20%);
    &::-webkit-meter-bar {
        background: darken($color-3--1, 20%);
        height: 100%;
        width: 100%;
    }
    &::-webkit-meter-optimum-value {
        height: 100%;
        background: $color-1--2;
    }
    &:-moz-meter-optimum {
        background: darken($color-3--1, 20%);
        border: none;
    }
    &:-moz-meter-optimum::-moz-meter-bar {
        background: $color-1--2;
    }
    &:-moz-meter-sub-optimum::-moz-meter-bar {
        background: antiquewhite;
    }
    &:-moz-meter-sub-sub-optimum::-moz-meter-bar {
        background: steelblue;
    }
}

@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, meter {
        display: none;
    }
}
