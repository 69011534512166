// Contact
.column-bloc.bloc-contact .contact-wrap {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

//news
.column-bloc.bloc-news {
 }

//publications
.bloc-publications__picture {
    float: none;
    text-align: center;
}
.bloc-publications {
    .column-bloc__wrapper .bloc-news__wrapper, .list-document-1__listitems {
        display: block;
        text-align: center;
    }
    .link-view {
        text-align: center;
    }
}
