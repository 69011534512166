.home-bloc-publications {
    .publications {
        padding-bottom: 3em;
        .title-1 {
            margin-left: 30px;
            &:after {
                height: 1px;
            }
        }
    }
    .bloc-publications__item {
        text-align: center;
    }
    .publications__wrapper {
        display: table;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }
    //.bloc-publications__picture, .publications__wrapper, .publications__summary {
    //    left: 50%;
    //    position: relative;
    //    transform: translateX(-50%);
    //}
    .publications__summary {
        display: block;
        max-width: 100%;
        margin-top: 20px;
        float: inherit;
        text-align: left;
        &:before {
            content: '';
            width: 80%;
            height: 1px;
            display: inline-block;
            background: #c5bbaf;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            margin-bottom: 20px;
        }
    }
    .publications__summary--wrapper {
        margin-top: 20px;
    }
    .newsletter .list-content-1 li {
        display: inline-block;
    }
    .newsletter__info {
        margin-bottom: 20px;
        padding-right: 25%;
    }
    .newsletter__forms {
        display: block;
    }
    .newsletter__fields:first-child {
        margin-right: 0;
        margin-bottom: 27px;
    }
}

