.flash-info {
    position: relative;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    padding: 15px;
    background-color: $color-3--6;
    width: 100%;
    margin: 0 auto;
    height: 190px;
    background-image: url('../Images/background/flash-info-bg.png');
    background-repeat: no-repeat;
    background-position-y: -160px;
    background-position-x: -95px;
    .flash-infos-wrap {
        display: table;
        position: absolute;
        left: 55%;
        transform: translateX(-50%);
        top: 35px;
        max-width: 700px;
    }
    .flash-info__picture {
        display: block;
    }
    .flash-infos__wrap-content {
        display: table-cell;
        padding-left: 20px;
        vertical-align: middle;
    }
    .flash-info__wrapper {
        display: inline-block;
        position: absolute;
        top: 45%;
        transform: translateY(-50%);
        left: 7%;

    }
    .flash-info__picture--attention {
        display: inline-block;
        margin-right: 10px;
    }
    .title {
        font-size: 3em;
        font-family: $typo-1;
        display: inline-block;
        padding-right: 20px;
        color: $color-5--1;
        vertical-align: top;
        font-weight: $bold;
        text-transform: uppercase;
    }
    .category {
        display: inline-block;
        color: $color-white;
        font-weight: $bold;
    }
    .flash-info-content {
        font-size: 1.9em;
        font-style: $typo-2;
        font-weight: $light;
        color: $color-white;
    }
    .button-1 {
        background-color: transparent;
        position: absolute;
        top: 10px;
        right: 10px;
        &:before {
            content: "\e022";
            color: $color-white;
            font-size: 2em;
        }
    }
    .button-2 {
        height: 70px;
        width: 70px;
        transform: rotate(45deg) translateX(-50%);
        border-radius: 20%;
        background-color: $color-4--3;
        box-shadow: 4px 3px 6px rgba(0, 0, 0, 0.5);
        top: 40%;
        float: right;
        margin-right: 10%;
        &:hover, &:focus {
            background-color: $color-4--1;
        }
        &:before {
            transform: translateY(-50%) translateX(-50%) rotate(-45deg);
            content: '\e000';
        }
    }
}
