.map {
    padding-top: 8em;
    padding-bottom: 8em;
    position: relative;
    .wrapper-main {
        position: relative;
    }
    .title-1 {
        color: $color-3--7;
        text-align: left;
        margin-bottom: 1em;
        a {
            color: inherit;
        }
        &:before {
            content: none;
        }
        &:after {
            background: $color-3--6;
        }
    }
    .list-square {
        .square-2 {
            background-color: $color-5--2;
        }
        &.square-right {
            .square-1 {
                background-color: $color-5--3;
            }
            .square-3 {
                background-color: $color-5--1;
            }
        }
    }
}
