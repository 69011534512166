.list-type-1--event {
    .list-type-1__picture, .list-type-1__wrapper-date, .list-type-1__wrapper {
        display: table-cell;
        vertical-align: top;
    }
    .list-type-1__picture {
        float: none;
    }
    .list-type-1__wrapper-date {
        padding-right: 2em;

        border-right: 1px solid $color-3--3;
    }
    .list-type-1__wrapper {
        padding-left: 2em;
    }
    .date-1 time {
        position: relative;
        top: -50px;
    }
    .link-bloc {
        &:hover, &:focus {
            .category {
                color: $color-5--3;
            }

        }
    }
    .category {
        background: $color-3--7;
        padding: 5px 5px;
        color: $color-5--1;
        display: inline-block;
    }
}
