.column-bloc.bloc-go-so-far {
    position: relative;
    .list-square {
        .square-1 {
            background: $color-4--2;
        }
        .square-2 {
            background: $color-4--3;
        }
        .square-3 {
            background: $color-4--4;
        }
    }
}
