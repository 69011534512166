// @name Wrapper with wide 940px
.wrapper-main {
    width: 100%;
    max-width: 96rem;
}

.header__top__wrapper {
    .social-networks {
        display: none;
    }
}
.header {
    padding-bottom: 0;
    max-width: 100%;
    padding-top: 30px;
    height: 90px;
    &:before {
        height: 70px;
        width: 70px;
        content: "";
        background-image: url("../Images/background/bg-header-mobile.png");
        background-repeat: no-repeat;
        position: absolute;
        display: block;
        right: 0;
        top: 20px;
        z-index: 30;
    }
}
.home {
    .header {
        padding-top: 12px;
        border-radius: 0;
        min-height: 72px;
    }
    .header__wrapper {
        top: 0;
        width: 100%;
        margin-left: 0;
        padding-top: 0;
    }
}

.header__top__wrapper {
    display: none;
}

.column-bloc {
    margin: 0 auto;
    margin-bottom: 3em;
}
