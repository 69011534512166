.carousel-1__title-1 {
    font-size: 2.4em;
    max-width: 280px;
}
.carousel-1 {
    margin-top: 70px;
    .background-square {
        &:after {
            top: -95px;
            height: 350px;
            width: 350px;
        }
    }
    .header__middle__go-to-main {
        .button-2 {
            width: 100px;
            height: 100px;
            &:before {
                left: 61px;
                top: 62px;
            }
        }
    }
}

.carousel-1__control {
    margin-right: 5px;
}
