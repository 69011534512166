// @name site informations
.site-infos {
    flex-basis: 60%;
    font-family: $typo-1;
    position: relative;
    font-style: normal;
    &:after {
        content: "";
        height: 160px;
        width: 1px;
        background: $color-3--6;
        display: inline-block;
        position: absolute;
        top: 0;
        right: 50px;
    }

    .site-infos__img-wrap {
        float: left;
        margin-right: 2em;
        position: relative;
        top: -62px;
        left: 105px;
        &:after {
            content: '';
            position: absolute;
            top: -7px;
            right: -23px;
            height: 180px;
            width: 180px;
            transform: rotate(45deg);
            border-radius: 25%;
            background: $color-white;
            z-index: 0;
            box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.5);
        }
        img {
            position: relative;
            z-index: 2;
        }
    }
    .site-infos__wrapper {
        overflow: hidden;
        position: relative;
        padding-left: 25%;
    }
    .place-info {
        text-transform: uppercase;
        font-size: 1.4em;
        font-weight: $light;
        margin-bottom: 25px;
        font-style: normal;
    }
    .title-info {
        font-size: 1.15em;
        color: $color-5--1;
        font-weight: $bold;
    }
    .list-infos__phone {
        font-size: 1.9em;
        font-weight: $normal;
        margin-bottom: 0;
        font-style: normal;
        &:before {
            color: $color-5--3;
        }
        a {
            color: $color-5--3;
            text-decoration: none;
        }
    }
    .list-infos__fax {
        font-size: 1.6em;
        font-style: normal;
        &:before {
            color: $color-white;
            font-size: 0.9em;
        }
        a {
            text-decoration: none;
            margin-left: 5px;
        }
    }
    p, ul {
        font-size: 1.3em;
        margin: em(.5, 1.3) 0 em(.8, 1.3);
        color: $color-white;
        &:first-child {
            margin-top: 0;
        }
    }
    ul {
        > li {
            margin: 0.2em 0;
        }
    }
    a {
        color: $color-white;
        text-decoration: underline;
        &:hover, &:focus {
            text-decoration: none;
        }
    }
    .info, .info-2 {
        font-style: normal;
        font-size: 1.4em;
        margin-bottom: 0;
    }
    .info-contact {
        text-transform: uppercase;
        position: absolute;
        top: 167px;
        left: -6px;
        font-size: 1.3em;
        a {
            span {
                font-family: $typo-1;
                transform: rotate(-45deg);
                position: relative;
                display: inline-block;
                font-weight: 400;
                font-size: 0.8em;
                white-space: nowrap;
                top: 65px;
                left: 23px;
            }
            &:hover {
                color: $color-white;
                span {
                    text-decoration: underline;
                }
            }
        }
    }
    .button-2 {
        height: 80px;
        width: 80px;
        transform: rotate(45deg);
        border-radius: 20%;
        position: absolute;
        bottom: 40px;
        left: 0;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
        &:before {
            content: "\e02a";
            font-size: 1.2em;
            top: 30px;
            left: 30px;
            transform: rotate(-45deg);
            bottom: unset;
        }
        &:hover {
            &:before {
                color: $color-3--7;
            }
        }
    }

}
