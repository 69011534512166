.services-bloc {
    .list-square {
        display: none;
        &.square-right {
            display: initial;
        }
    }
    .title-1:before, .title-1:after {
        content: none;
    }
}

.service-gallery__listitems{
    min-height: 270px;
}

.service-gallery-clone .service-gallery__listitems {
    margin-left: 11%;
}

.service-gallery__article--link.background-square {
    &:before {
        transform: rotate(45deg) translateX(-42%) translateY(33%);
    }
    &:after {
        transform: rotate(45deg) translateX(-41%) translateY(33%);
    }
}
