.flash-info {
    height: 150px;
    .title {
        font-size: 2em;
    }
    .flash-info__picture--attention {
        img {
            width: 30px;
            height: auto;
        }
    }
    .flash-info__wrapper {
        left: 1%;
    }
    .flash-info__picture {
        img {
            max-width: 100px;
            height: auto;
        }

    }
    .flash-infos__wrap-content {
        max-width: 350px;
    }
    .flash-info-content {
        font-size: 1.5em;
    }
    .button-2 {
        margin-right: 0;
    }
}
