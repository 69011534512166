.events {
    .events__listitem {
        margin: 0 0;
    }
    .bloc-event__item  {
        flex-basis: 46%;
    }
    .link-view__feature {
        right: 10px;
    }
}
