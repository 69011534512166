.news-bloc {
    .title-1 {
        text-align: left;
        &:before, &:after {
            content: none;
        }
    }
    .list-square {
        display: none;
        &.square-right {
            display: initial;
        }
    }
    .link-view__feature{
        right: 15px;
    }
    .news-bloc__listitem {
        display: block;
    }
    .bloc-news .bloc-news__item.bloc-news__item--focus {
        flex-basis: 98%;
        margin: 0 0;
        margin-bottom: 35px;
        border-radius: 0;
    }
    .bloc-news .bloc-news__item--focus {
        flex-basis: 100%;
        margin: 0;

        .bloc-news__picture {
            display: block;
            position: relative;
            width: 96%;
            margin: 0 0;
            transform: translateX(-50%);
            top: -20px;
            left: 50%;
            .news--image {
                clip-path: unset;
                min-height: inherit;
                width: 100%;
                height: auto;
            }
        }
        .bloc-news__wrapper {
            position: relative;
            max-width: 100%;
            margin: 0 40px;
            top: 0;
            transform: none;
            float: none;
            padding-bottom: 40px;
        }
    }

}

