// @name Cross menu
.footer {
    .menu-cross {
       /* flex-grow: .9;
        flex-shrink: .9;*/
    }
}

.menu-cross {
    text-transform: uppercase;
    overflow: hidden;
    flex-basis: 20%;
    position: relative;
    font-family: $typo-1;
    &:after {
        right: 40px;
        content: "";
        height: 160px;
        width: 1px;
        background: $color-3--6;
        display: inline-block;
        position: absolute;
        top: 0;
    }

    li {
        vertical-align: top;
        display: block;
        padding: .2em 1.5em;
        @include default-icons-absolute-before('\e011', 1.6em, $color-5--1, 0, inherit, inherit, 0);
        a {
            display: block;
            color: $color-white;
            text-decoration: none;
            font-size: 1.4em;
            line-height: 1.2em;
            &:hover, &:focus {
                color: $color-5--1;
                span {
                    text-decoration: underline;
                }
            }
        }
        &.active a {
            color: $color-5--1;
            text-decoration: none;
            font-weight: $bold;
        }
        &:last-child {
            margin-top: 30px;
            &:after {
                content: '';
                height: 3px;
                width: 60px;
                background-color: $color-3--6;
                top: -35px;
                left: -9px;
                position: relative;
                display: block;
            }
        }
    }
}
