.join-us {
    padding-top: 12em;
    padding-bottom: 2em;
    margin-bottom: 3em;
    &__wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .tools {
        width: auto;
    }
    .tools__item {
    }
    .background-square {
        width: 60px;
        height: 60px;
        margin: 0 2em;
        &:after {
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
        }
    }
    .tooltip-css {
        display: block;
        margin: -10%;
        width: 120%;
        height: 120%;
        position: relative;
    }
    .wrapper-img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.join-us__wrapper {
    width: 100%;
    text-align: right;
}

.join-us__title {
    font-size: 2.2em;
    font-family: $typo-1;
    font-weight: $light;
    text-transform: uppercase;
    display: inline;
    text-align: center;
    color: $color-black;
    margin-right: 35px;
    vertical-align: bottom;

}


.tool__item--back--fb.background-square {
    &:after {
        background: $color-6--1;
    }
    .tooltip-css {
        &:before {
            background: $color-6--1;
        }
        &:after {
            border-left-color: $color-6--1;
        }
    }
}

.tool__item--back--twitter.background-square {
    &:after {
        background: $color-6--2;
    }
    .tooltip-css {
        &:before {
            background: $color-6--2;
        }
        &:after {
            border-left-color: $color-6--2;
        }
    }
}

.tool__item--back--instagram.background-square {
    &:after {
        background: $color-6--3;
    }
    .tooltip-css {
        &:before {
            background: $color-6--3;
        }
        &:after {
            border-left-color: $color-6--3;
        }
    }
}

.tool__item--back--youtube.background-square {
    &:after {
        background: $color-6--4;
    }
    .tooltip-css {
        &:before {
            background: $color-6--4;
        }
        &:after {
            border-left-color: $color-6--4;
        }
    }
}
