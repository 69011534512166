// Newsletter inscription
.newsletter {
    color: $color-white;
    flex-basis: 30%;
    font-family: $typo-1;
    .newsletter__fields {
        display: table;
        .newsletter__fields-wrapper, .newsletter__button-wrapper {
            display: table-cell;
        }
    }
    .newsletter__title {
        margin-bottom: .5em;
        font-size: 1.6em;
        font-weight: $bold;
        color: $color-5--1;
        text-transform: uppercase;
    }
    form {
        margin: 0;
    }
    fieldset {
        margin-bottom: .5em;
    }
    input {
        border: none;
        background: $color-3--6;
        width: 250px;
        height: 50px;
        color: $color-white;
    }
    label {
        color: $color-white;
        font-weight: $light;
        text-transform: uppercase;
        .text-help {
            text-transform: none;
        }
    }
    li a {
        color: $color-white;
    }
    .text-help {
        color: $color-white;
    }
    /*
    input {
      padding-top: em(1, $input__font-size);
      padding-bottom: em(1, $input__font-size);
    }
    */
    .list-content-1 {
        margin-top: 15px;
        li {
            display: inline-block;
            font-weight: $normal;
            position: relative;
            padding-left: 9px;
            margin: 0 0;
            margin-right: 30px;
            padding-left: 15px;
            a {
                font-family: $typo-1;
                margin-left: 8px;
                &:hover, &:focus {
                    color: $color-5--1;
                }
            }
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: -3px;
                height: 20px;
                width: 20px;
                transform: rotate(45deg);
                border-radius: 20%;
                background: $color-3--6;
                z-index: 0;

            }
            &.desabonnement:before,  &.archives:before {
                color: $color-white;
                z-index: 1;
                position: absolute;
                font-size: 0.8em;
            }
            &.archives:before {
                content: '\e03e';
                left: 2px;
                top: 4px;
            }
            &.desabonnement:before {
                content: '\e002';
                top: 4px;
                left: 1px;
            }
        }
    }
    .button-2 {
        transform: rotate(45deg);
        border-radius: 20%;
        height: 60px;
        width: 60px;
        margin-left: -40px;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
        svg {
            transform: rotate(-45deg);
            width: 1em;
            height: 1em;
        }
    }
}

.newsletter__fields-wrapper, .newsletter__button-wrapper {
    display: inline-block;
    vertical-align: bottom;
}

.newsletter__links {

}
