// @name Header page
.home {
    .header__wrapper {
        display: block;
        position: relative;
        top: 150px;
        left: 0;
        z-index: 160;
        padding-top: 30px;
        width: 94vw;
        margin: 0 auto;
        border-radius: 12px;
        &:after, &:before {
            content: "";
            position: absolute;
            width: 50%;
            height: 6px;
            z-index: 2;
        }
        &:after {
            bottom: 23px;
            right: 60px;
            transform: rotate(1deg);
            box-shadow: 0px 23px 17px $color-black;
        }
        &:before {
            bottom: 23px;
            left: 60px;
            transform: rotate(-1deg);
            box-shadow: 0px 23px 17px $color-black;
        }
    }
    .header {
        padding-top: 30px;
        border-radius: 12px;
        position: relative;
    }
    .search-box {
        border-radius: 12px;
    }
    .search-box .ddm > .ddm__sub-level {
        margin-top: 0;
        border-radius: 12px;
    }
}
.header {
    @extend .wrapper-main;
    @extend .clear-fix;
    max-width: inherit;
    margin-left: 0;
    display: flex;
    margin-top: -30px;
    padding-top: 60px;
    padding-bottom: 10px;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: relative;
    background-image: url('../Images/background/bg-3.png');
    background-repeat: no-repeat;
    background-position-x: -78px;
    background-position-y: -130px;
    background-color: $color-white;
    z-index: 3;
}

// @name Top of content
.top-of-content {
    background-image: url('../Images/background/bg-0.png');
    position: relative;
    z-index: 1;
    margin-bottom: 10px;
}

.top-of-content__wrapper {
    @extend .wrapper-main;
    padding-top: 2em;
}

// @name Main wrapper
.main {
    width: 100%;
    clear: both;
    margin-top: -30px;
}

.home {
    .main {
        margin-top: -100px;
    }
}
// @name Main section
.section-main {

}

// @name Main section wrappers
.section-main__wrapper {
    @extend .wrapper-main;
    padding-top: 7em;
    padding-bottom: 9em;
}

.section-main__wrapper-2 {
    display: table;
    width: 100%;
    > * {
        display: table-cell;
        vertical-align: top;
    }
}

// @name Main section content
.section-main__content {
    width: 100%;
    img {
        border-radius: 3%;
    }
}

// @name Main section aside
.section-main__aside {
    > .column-bloc {
        *:first-child {
            margin-top: 0;
        }
    }
}

// @name Footer
.footer {
    background: $color-3--7;
    margin-top: 0px;
    position: relative;
    .footer__wrapper {
        @extend .wrapper-main;
        padding-top: 4em;
        padding-bottom: 3em;
    }
    .footer__wrapper-2 {
        margin: 0 -1.5em;
        display: flex;
        width: 100%;
        > * {
          /*  flex: 1 1 100%;
            margin: 0 1.5em;*/
        }
    }
    .svg-city {
        width: 390px;
        height: auto;
        fill: $color-3--7;
        position: absolute;
        top: -102px;
        left: -23px;
        z-index: 0;
    }
}

.header__top__wrapper {
    height: 30px;
    position: relative;
    z-index: 500;
}
