.service-gallery__listitems {
    min-height: 255px;
}

.service-gallery__article {
    margin-left: 0;
    width: auto;
    opacity: 1 !important;
    &:first-child {
        margin-left: 0;
    }
    &:nth-child(2n) {
        top: inherit;
    }
}

.service-gallery__article--link.background-square {
    &:before {
        left: 50%;
        height: 150px;
        width: 150px;
        transform: rotate(45deg) translateX(-45%) translateY(31%);
    }
    &:after {
        left: 50%;
        height: 160px;
        width: 160px;
        box-shadow: 8px -5px 6px rgba(0, 0, 0, 0.2);
        transform: rotate(45deg) translateX(-44%) translateY(31%);
    }
}

.service-gallery__article--title {
}

.service-gallery__prev, .service-gallery__next {
    button {
        height: 200px;
    }
}

.services-bloc {
    .wrapImg {
        top: 19%;
        left: 48%;
        bottom: inherit;
        transform: translateX(-50%);
    }
    .link-view {
        &.background-square:after {
            height: 60px;
            width: 60px;
        }
        .button-2 {
            height: 50px;
            width: 50px;
            &:before {
                top: 42%;
            }
        }
    }
}

.service-gallery__item--plus {
    right: 60px;
    top: 182px;
}
