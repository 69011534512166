.header__top__wrapper {
    display: flex;
    flex-wrap: nowrap;
    .img-wrap {
        width: 70%;
    }
    .social-networks {
        position: absolute;
        left: 55px;
        top: 12px;
        z-index: 5;
        &-item {
            float: left;
            display: block;
            width: 30px;
            height: 30px;
            margin-right: 10px;
            &.facebook {
                a:before {
                    content: '\e099';
                }
            }
            &.twitter {
                a:before {
                    content: '\e09b';
                }
            }
            &.youtube {
                a:before {
                    content: '\e09c';
                }
            }
        }
        a {
            display: block;
            background: $color-3--6;
            border-radius: 20%;
            transform: rotate(45deg);
            height: 30px;
            width: 30px;
            &:hover {
                background: $color-3--7;
            }
            &:before {
                font-family: icons-default;
                font-size: 1.5em;
                color: #fff;
                position: absolute;
                transform: rotate(-45deg) translateY(50%) translateX(10%);
            }
        }
    }
    .weather__wrapper {
        height: 30px;
        width: 100%;
        background: $color-3--7;
        float: right;
        text-align: right;
        padding-right: 20px;
        .title, .date, .temp-min, .temp-max {
            font-family: $typo-1;
            display: inline-block;
            text-transform: uppercase;
            color: #b3b2bc;
            height: 30px;
            margin: 0 0;
            padding-top: 5px;
        }
        .title {
            padding-right: 12px;
            font-weight: $normal;
            font-size: 1.5em;
            border-right: 1px solid #b3b2bc;
        }
        .date {
            margin-left: 12px;
            font-weight: $light;
            margin-right: 20px;
        }
        .weather__img {
            width: 32px;
            height: auto;
            position: relative;
            top: -1px;
        }
        .temp-min, .temp-max {
            font-weight: $bold;
            font-size: 1.2em;
        }
        .temp-min {
            color: #83dce6;
            margin-left: 5px;
        }
        .temp-max {
            color: #ffa2a3;
            margin-left: 5px;
        }
    }
}
