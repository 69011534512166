.carousel-1 {
    .background-square:after {
        top: -67px;
        height: 80px;
        width: 80px;
    }
    .header__middle__go-to-main {
        bottom: 88px;
        height: 40px;
        .button-2 {
            height: 80px;
            width: 80px;
            left: 50%;
            transform: rotate(45deg) translateX(-57%) translateY(12%);
            &:before {
                left: 51px;
                top: 52px;
            }
        }
    }
    .prev-carousel-1, .next-carousel-1 {
        top: 35%;
    }
}

.carousel-1__item {
    overflow: visible;
}

.carousel-1__wrapper {
    position: relative;
    bottom: 0;
}

.carousel-1__title-1 {
    color: $color-black;
    max-width: 100%;
    font-size: 1.6em;
}

.carousel-1__title {
    top: 7px;
    background: $color-white;
    min-height: 96px;
}

.carousel-1__category {
    background-color: $color-black;
    padding: 0 10px;
}

