#pieChartTarget {
    float: none;
    margin: 0 auto;
}

.rte {
    .list-pie-chart {
        width: 100%;
        float: none;
    }
}
