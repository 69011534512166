.go-to-top {
    margin-top: inherit;
    display: block;
    width: 100%;
    right: 3%;
    bottom: 0;
    z-index: 102;
    p {
        text-align: right;
        position: absolute;
        bottom: 7px;
        right: 0;
    }
    a {
        &:before {
            content: "\e015";
        }
    }
    .button-1 {
        background: $color-3--6;
        border-radius: 20%;
        transform: rotate(45deg);
        height: 50px;
        width: 50px;
        &:before {
            color: $color-white;
            transform: translateY(-50%) translateX(-50%) rotate(-45deg);
            font-size: 1.3em;
        }
    }
}
