.main {
    margin-top: 0;
}

.top-of-content {
    margin-bottom: 0;
    .tools__listitems {
        display: inline-flex;
        align-items: center;
    }
}

.section-main__wrapper-2 {
    display: flex;
    flex-direction: column;
    > * {
        display: block;
        vertical-align: inherit;
    }
}
