// @name Breadcrumb
.breadcrumb {
    $breadcrumb__font-size: 1.3;
    font-family: $typo-2;
    display: inline-block;
    vertical-align: middle;
    width: 68%;
    text-transform: uppercase;
    p {
        font-size: #{$breadcrumb__font-size}em;
        font-weight: $bold;
        color: $color-4--1;
        .breadcrumb__here {
            color: $color-3--3;
        }
        a {
            font-weight: $normal;
            color: $color-3--3;
            @include default-icons-after('\e027', 0 0 0 5px, em(1.2, $breadcrumb__font-size), $color-3--3, em(-.2, 1.2));
            &:last-child {
                &:after {
                    color: $color-4--1;
                }
            }
        }
    }
}
