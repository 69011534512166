.carousel-1 {
    position: relative;
    margin-bottom: 8em;
    a {
        &:focus {
            outline: 2px dashed $color-5--4;
        }
        &:hover{
            text-decoration: none;
            .carousel-1__title-1 {
                text-decoration: underline;
            }
        }
    }
    button {
        &:focus {
            outline: 2px dashed $color-5--4;
        }
    }
    .background-square:after {
        top: -133px;
        right: 50%;
        height: 450px;
        transform: translateX(50%) rotate(45deg);
        width: 450px;
        border-radius: 9%;
        background: rgba(0, 0, 0, 0.5);
    }
    .header__middle__go-to-main{
        bottom: -14px;
        height: 90px;
        &:after {
            content: '';
            z-index: 2;
            top: 0;
            transform: translate(-50%,-50%);
            border-radius: 100%;
            width: 80px;
            height: 80px;
        }
        .button-2 {
            background: transparent;
            box-shadow: 0 0 0 2000px #fff;
            left: 49%;
            height: 140px;
            width: 140px;
            &:before {
                color: $color-white;
                left: 91px;
                top: 90px;
            }
            &:after {
                content: none;
            }
        }
    }
}
.carousel-1__listitems {
    text-align: center;
}
.carousel-1__item {
    width: 100%;
    overflow: hidden;
    .link-block {
        display: block;
    }
}
.carousel-1__picture {
    display: block;
    img {
        width: 100%;
        height: auto;
    }
}
.carousel-1__wrapper {
    position: absolute;
    bottom: 44px;
    left: 50%;
    width: 80%;
    transform: translateX(-50%);
}

.carousel-1__category {
    font-family: $typo-1;
    font-size: 1.6em;
    color:$color-5--4;
    font-weight: $normal;
    text-transform: uppercase;
}

.carousel-1__title {
    position: relative;
    top: -60px;
}

.carousel-1__title-1{
    font-family: $typo-1;
    font-size: 3em;
    font-weight: $bold;
    text-transform: uppercase;
    color: $color-white;
    max-width: 360px;
    display: block;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
}

.carousel-1__more-infos {
    margin-bottom: 7.6em;
    .button-1 {
        &:before {
            content: "\e000";
        }
    }
}
.prev-carousel-1, .next-carousel-1 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
}
.prev-carousel-1 {
    left: 0;
}
.next-carousel-1 {
    right: 0;
}
.carousel-1__control {
    text-align: right;
    margin-right: 50px;
    margin-top: -65px;
    position: relative;
    z-index: 140;
    .button-stop {
        transition: background ease .3s;
        padding: 0 5px 5px 5px;
        &:hover, &:focus {
           background: $color-5--0;
        }
    }
}
