.logo {
    font-size: 1.3em;
    margin: 0 20px;
    flex-basis: 27%;
    //margin-left: 40px;
    &.background-square:after {
        height: 270px;
        width: 270px;
        background: $color-3--4;
    }
    a {
        display: block;
        &:hover {
            text-decoration: none;
        }
    }
    img {
        margin-right: 10px;
        max-width: 100%;
        height: auto;
    }
}
