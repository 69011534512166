.top-of-content {
    .tools {
        display: none;
    }
}

// @name Main section wrappers
.section-main__wrapper {
    width: 100%;
    padding-top: 3em;
    padding-bottom: 3em;
}

.home {
    .header__wrapper {
        &:before {
            left: 0;
        }
        &:after {
            right: 0;
        }
    }
}

// @name Footer
.footer {
    margin-top: 5em;
    .footer__wrapper-2 {
        margin: 0;
        display: block;
        > * {
            flex: inherit;
            margin: 0 0 2em 0;
        }
    }
}
