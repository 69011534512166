.list-type-1--news {
    .button-1 {
        background: $color-3--5;
        border-radius: 20%;
        transform: rotate(45deg);
        height: 45px;
        width: 45px;
        svg {
            fill: $color-black;
            transform: rotate(-45deg);
            top: 1px;
            left: 5px;
            position: relative;
        }
        &:hover {
            background: $color-4--2;
            svg {
                fill: $color-white;
            }
        }
    }
    .category {
        a {
            color: $color-4--2;
        }
    }
}
