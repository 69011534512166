// @name Liste type 1
// @description Basic list
.list-type-1 {
    margin-bottom: 5em;
    @extend .clear-fix;
    .list-type-1__date-2 {
        margin-top: em(-1, $date-2__font-size);
    }
    .button-1--rss {
        background: $color-3--5;
        border-radius: 20%;
        transform: rotate(45deg);
        height: 45px;
        width: 45px;
        svg {
            fill: $color-black;
            transform: rotate(-45deg);
            top: 1px;
            left: 5px;
            position: relative;
        }
        &:hover {
            background: $color-4--2;
            svg {
                fill: $color-white;
            }
        }
    }
}

.list-type-1__item {
    padding: 2em 0;
    @extend .clear-fix;
    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        padding-bottom: 0;
    }
}

.list-type-1__picture {
    display: block;
    float: left;
    img {
        margin-right: 2em;
        max-width: 220px;
        height: auto;
    }
}

.list-type-1__wrapper {
    overflow: hidden;
}

.list-type-1__title {
    font-family: $typo-3;
    color: $color-black;
    font-size: 2em;
    font-weight: $bold;
    margin: .2em 0 .8em;
    a {
        color: $color-black;
    }
}
