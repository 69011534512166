// @name Search box
// @description Search module
.search-box {
    width: auto;
    margin-top: 0;
    overflow: visible;
    margin-right: 0;
    .search-box__fields-wrapper, .search-box__button-wrapper {

    }
    .search-box__fields-wrapper {
        width: 50%;
        .search-box__field-wrapper--in-line {
            label {
                display: block;
                text-align: left;
            }
            input {
                display: inherit;
            }
        }
    }
    .search-box__button-wrapper {
        padding-left: 1em;
    }
    // Drop down menu
    .ddm {
        > .ddm__sub-level {
            z-index: 102;
            padding: 1em;
            background: $color-white;
            .search-box__field-wrapper {
                margin-top: 0;
            }
            .button-2 {
                display: none;
            }
            input {
                width: 100%;
            }
            .search-box__button-wrapper {
                .button-1{
                    &:after {
                        height: 40px;
                        width: 40px;
                        top: 5px;
                        right: 6px;
                    }
                }
            }
        }
    }
    .text-help {
        z-index: 1;
        top: -5px;
        width: 100%;
        text-align: left;
        left: 120px;
    }
    .input-text:focus + .text-help, .text-help:focus {
        top: -5px;
    }
    .button-1 {
        svg {
            z-index: 2;
            width: 1.3em;
            height: 1.3em;
        }
        &:after {
            z-index: 0;
        }
    }
}

.home .search-box .ddm > .ddm__sub-level {
    border-radius: 0;
}
