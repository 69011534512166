.home-bloc-publications {
    .publications {
        padding-left: 10px;
    }
    .bloc-publications__title {
        font-size: 2.2em;
    }
    .bloc-publications__item {
        display: inline-block;
    }
    .publications__wrapper {
        max-width: 32%;
        display: inline-block;
        min-width: inherit;
    }
    .bloc-publications__wrapper {
        display: block;
    }
    .bloc-publications__picture {
        display: inline-block;
        padding-left: 10px;
    }
    .bloc-publications__newsletter {
        padding-top: 4em;
        padding-right: 10px;
        padding-left: 30px;
        .title-1:after {
            width: 17%;
        }
    }
    .publications__summary {
        padding-left: 0;
        max-width: 30%;
        float: right;
    }
    .newsletter__info {
        display: block;
        width: 100%;
    }
    .newsletter__forms {
        //display: inline-block;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .newsletter__fields {
        margin-right: 65px;
    }
    .newsletter .list-content-1 {
        margin-top: 10px;
        //display: inline-block;
        //margin-left: 40px;
    }
}
