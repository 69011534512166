.image-bloc {
    background: $color-4--3;
    padding-top: 9em;
    padding-bottom: 6em;
    position: relative;
    .title-1 {
        &:before, &:after {
            background: $color-4--4;
        }
    }
    .list-square {
        .square-1 {
            background-color: $color-4--4;
        }
        .square-2 {
            background-color: $color-4--2;
        }
        .square-3 {
            background-color: $color-4--1;
        }
        &.square-right {
            .square-1 {
                background-color: $color-4--1;
            }
            .square-3 {
                background-color: $color-4--4;
            }
        }
    }
    .image-bloc__listitem {
        display: flex;
        margin: 0 -2%;
        margin-bottom: 80px;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .image-bloc__item {
        flex-basis: 31.3333%;
        display: block;
        margin: 0 1%;
        box-shadow: 5px 8px 15px rgba(0, 0, 0, 0.4);
        border-radius: 3%;
        a {
            display: block;
            width: 100%;
            overflow: hidden;
            position: relative;
            text-decoration: none;
            &:hover, &:focus {
                .item__content {
                    top: 0;
                }

                .video {
                    &:before, &:after {
                        visibility: hidden;
                        transition: visibility ease .3s;
                    }
                }
            }
        }
    }
    .item___img {
        display: block;
        img {
            width: 100%;
            height: auto;
            border-radius: 3%;
        }
    }
    .video {
        position: relative;
        &:before {
            font-family: icons-default;
            content: "\e027";
            font-size: 2em;
            position: absolute;
            top: 50.3%;
            left: 50.7%;
            color: $color-white;
            transform: translateX(-50%) translateY(-50%);
            z-index: 1;
            text-decoration: none;
        }
        &:after {
            content: '';
            background-color: rgba(40, 39, 49, 0.6);
            height: 50px;
            width: 50px;
            position: absolute;
            border-radius: 25%;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
        }
    }
    .item__content {
        color: $color-3--7;
        text-align: center;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: auto;
        min-height: 293px;
        background: rgba(236, 115, 87, .7);
        transition: top ease .3s .1s;
        border-radius: 3%;
        .category {
            font-family: $typo-1;
            font-weight: $normal;
            margin-bottom: 10px;
            text-transform: uppercase;
            color: $color-3--7;
            font-size: 1.4em;
            margin-top: 70px;
        }
        .content-title {
            font-family: $typo-1;
            text-transform: uppercase;
            margin-bottom: 15px;
            font-size: 2.2em;
            font-weight: $bold;
            display: inline-block;
        }
        .number {
            font-family: $typo-2;
            font-weight: $normal;
            font-size: 1.8em;
            margin-bottom: 30px;
        }
        .button-1 {
            background-color: $color-3--7;
            border-radius: 20%;
            transform: rotate(45deg);
            &:before {
                content: '\e000';
                transform: rotate(-45deg) translateY(-50%) translateX(-50%);
                color: $color-white;
                font-size: 0.6em;
                top: 15px;
                left: 18px;
            }
            &:hover, &:focus {
                background: $color-4--2;
            }
        }
    }
    .link-view {
         position: absolute;
         bottom: -50px;
         left: 50%;
         transform: translateX(-50%);
        z-index: 1;
     }
    .button-2 {
        height: 65px;
        width: 65px;
        transform: rotate(45deg) translateX(-50%);
        border-radius: 20%;
        background-color: $color-4--1;
        box-shadow: 4px 3px 6px rgba(0, 0, 0, 0.5);
        left: 49%;
        z-index: 2;
        &:hover, &:focus {
            background-color: $color-4--2;
        }
        &:before {
            transform: translateY(-50%) translateX(-50%) rotate(-45deg);
            content: '\e000';

        }
    }

}

.home {
    .image-bloc {
        padding-bottom: 0;
        .button-2 {
            &:before {

            }
        }
    }
}

// image bloc on home page
.image-bloc {
    .image-bloc__item.image-bloc__listitems--small {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -2%;
        box-shadow: none;
        .item__content {
            min-height: 140px;
            .category {
                margin-top: 10px;
            }
            .content-title {
                font-size: 1.3em;
                margin-bottom: 10px;
            }
            .number {
                font-size: 1.5em;
                margin-bottom: 10px;
            }
            .button-1 {
                font-size: 1em;
                &:before {
                    top: 11px;
                    left: 13px;
                }
            }
        }
        .video {
            &:after {
                height: 40px;
                width: 40px;
            }
        }
    }

    .image-bloc__item--small {
        flex-basis: 48%;
        margin: 0 1%;
        border-radius: 3%;
        box-shadow: 5px 8px 15px rgba(0, 0, 0, 0.4);
        &:first-child, &:nth-child(2) {
            margin-bottom: 3.3333%;
        }
    }
}
