// max width for content element
$content__max-width: ($smartphone/10) - $column-bloc__width - $column-bloc__padding-left;

.rte {

    // @name Paragraph
    $content-paragraph__font-size: 1.4;
    p {
        font-size: #{$content-paragraph__font-size}em;
        margin: em(.5, $content-paragraph__font-size) 0 em(1, $content-paragraph__font-size) 0;
    }

    // @name Table
    .table-wrapper {
        position: relative;
    }
    .table-wrapper-fade {
        position: absolute;
        right: 0;
        width: 30px;
        height: 100%;
       // background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .8) 100%);
    }
    .table-wrapper-inner {
        max-width: #{$content__max-width}em;
    }
    table {
        caption {
            text-align: left;
        }
    }
}
