// @name Signature stratis
.stratis {
    background: $color-3--7;
    width: 100%;
    font-size: 1em;
    color: $color-white;
    text-align: right;
    font-family: $typo-1;
    text-transform: uppercase;
    span {
        display: none;
    }
    a {
        display: inline-block;
        padding: 4px 8px 4px 12px;
        color: #ffffff;
        background: #c02425;
        text-decoration: none;
        margin-left: 5px;
        font-weight: $bold;
        &:hover, &:focus {
            color: #A40015;
            background: #ffffff;
        }
    }
}
