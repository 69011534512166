.home-bloc-publications {
    .title-1 {
        text-align: left;
        color: $color-black;
        &:before {
            content: none;
        }
        &:after {
            height: 1.1px;
        }
    }
    .list-square {
        .square-2 {
            background-color: $color-5--2;
        }
        &.square-right {
            .square-1 {
                background-color: $color-5--3;
            }
            .square-3 {
                background-color: $color-5--1;
            }
        }
    }
    .bloc-publications__item {
        display: table;
    }
    .publications__wrapper {
        display: table-cell;
        vertical-align: top;
        padding-right: 10px;
        padding-left: 10px;
        //min-width: 300px;
        min-width: 250px;
    }
    .bloc-publications__picture {
        display: table-cell;
    }
    .publications__summary {
        display: table-cell;
        vertical-align: top;
        padding: 0 10px;
        position: relative;
        &:before {
            content: '';
            width: 1px;
            height: 235px;
            display: block;
            background: #c5bbaf;
            position: absolute;
            top: 0;
            left: -4px;
        }
        .title {
            font-family: $typo-1;
            text-transform: uppercase;
            font-size: 1.7em;
            margin-bottom: 20px;
            &:after {
                content: '';
                height: 4px;
                width: 40px;
                display: block;
                background: #c02425;
                position: relative;
                top: 5px;
            }
        }
    }
    .publications__summary--wrapper {
        display: table-cell;
        vertical-align: top;
        padding-left: 10px;
    }
    .summary__category {
        font-family: $typo-1;
        font-size: 1.3em;
        color: $color-black;
    }
    .summary__title {
        font-family: $typo-2;
        font-size: 1.6em;
        font-weight: $normal;
    }
    .summary__list {
        li {
            margin-bottom: 15px;
        }
    }
    .publications {
        padding-top: 8em;
        padding-bottom: 6em;
        flex-basis: 75%;
        background-image: url('../Images/background/bg-4.png');
        padding-left: 150px;
        .title-1 {
            margin-bottom: 30px;
            margin-left: 50px;
        }
        &.bloc-publications {
            .list-square {
                left: inherit;
            }
        }
    }
    .bloc-publications__title {
        font-size: 3em;
        text-align: left;
        a:hover, a:focus {
            color: $color-5--3;
        }
    }
    .file-info {
        font-family: $typo-1;
        font-size: 1.5em;
        text-transform: uppercase;
        text-align: left;
        color: $color-black
    }
    .bloc-publications__newsletter {
        padding-top: 8em;
        padding-bottom: 6em;
        flex-basis: 40%;
        background-image: url('../Images/background/bg-5.png');
        padding-right: 70px;
        padding-left: 40px;
        .title-1 {
            margin-bottom: 40px;
            &:after {
                width: 34%;
            }
        }
    }
    .newsletter__info {
        font-family: $typo-2;
        font-size: 1.8em;
        //width: 250px;
        width: 300px;
        color: $color-black;
        margin-bottom: 30px;
    }
    .newsletter__fields {
        margin-bottom: 16px;
    }
    label, legend, .text-help {
        color: $color-black;
    }
    label {
        font-weight: $normal;
        font-size: 1.8em;
    }
    .newsletter {
        input {
            width: 275px;
            background: $color-3--5;
            color: $color-black;
        }
        .list-content-1 {
            //margin-top: 30px;
            margin-top: 25px;
            padding-left: 6px;
            li {
                //display: block;
                margin-bottom: 10px;
                margin-right: 25px;
                a {
                    color: $color-black
                }
                &:before {
                    color: $color-3--5;
                }
            }
        }
        .button-2 {
            box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
        }
    }
    .bloc-publications {
        .link-view {
            text-align: center;
        }
    }
}

.bloc-publications__wrapper {
    display: flex;
    flex-wrap: nowrap;
}
