.services-bloc {
    background-image: url('../Images/background/bg-service.png');
    background-repeat: repeat-x;
    background-position-y: 59%;
    position: relative;
    z-index: 2;
    .title-1__wrapper:after {
        background-image: url('../Images/background/shadow-title2.png');
    }
    .title-1 {
        &:before, &:after {
            background: #b3b2bc;
        }
    }
    .list-square {
        .square-1 {
            background-color: $color-5--1;
        }
        .square-2 {
            background-color: $color-5--2;
        }
        .square-3 {
            background-color: $color-5--3;
        }
        &.square-right {
            .square-1 {
                background-color: $color-5--3;
            }
            .square-3 {
                background-color: $color-5--1;
            }
        }
    }
    a {
        color: $color-black;
        &:hover {
            text-decoration: none;
        }
    }
    .background-square:after {
        border-radius: 25%;
    }


    .link-view {
        &.background-square:after {
            background: $color-white;
            z-index: 0;
            height: 130px;
            width: 130px;
            left: -5px;
            top: -5px;
        }
        .button-2 {
            height: 120px;
            width: 120px;
            transform: rotate(45deg);
            border-radius: 25%;
            z-index: 2;
            &:before {
                content: '\e000';
                top: 47%;
                left: 50%;
                transform: rotate(-45deg) translateY(-50%) translateX(-50%);
            }
        }
        &:hover, &:focus {
            &.background-square:after {
                background: $color-white;
            }
            .button-2 {
                background: $color-5--4;
                &:before {
                    color: $color-5--3;
                }
            }
        }
    }
    .wrapImg {
        max-width: 77px;
        position: absolute;
        left: 50%;
        bottom: 50%;
        transform: translateX(-50%) translateY(50%);
        z-index: 3;
    }
    .wrapper-img {
        position: relative;
        display: block;
        background-repeat: no-repeat;
        background-size: 100%;
        max-width: 77px;
        background-position-x: 1000px;
        img {
            max-width: 77px;
        }
    }
}

.service-gallery {
    margin-top: -3em;
    position: relative;
    &.service-gallery-clone {
        display: block;
        position: relative;
    }
}

.service-gallery-clone {
    .service-gallery__listitems {
        width: 80%;
        margin-left: 10%;
    }
}

.service-gallery__listitems {
    min-height: 385px;
    text-align: center;
    vertical-align: middle;
}

.service-gallery__article {
    min-height: 230px;
    width: 225px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: -90px;
    transition: background ease .3s;
    white-space: normal;
    > .link {
        display: block;
        color: $color-black;
        text-align: center;
    }
    > a.link {
        .service-gallery__picture {
            position: relative;
        }
    }
    &:first-child {
        margin-left: 0;
    }
    &:nth-child(2n) {
        top: 135px;
         .service-gallery__article--link.background-square {
             &:after {
                 box-shadow: 3px -4px 6px rgba(0, 0, 0, .2);
             }
         }
    }
    &:hover, &:focus {
        .background-square {
            &:after {
                background: $color-4--3;
                transition: background ease .5s;
            }
            &:before {
                background: $color-4--2;
                transition: background ease .5s;
            }
        }
        .wrapImg {
            transition: background-size ease 1s;
            background-size: 70% 2px;
        }
        .wrapper-img {
            background-position-x: 0;
        }
        img {
            opacity: 0;
        }
        .service-gallery__article--title {
            color: $color-white;
            transition: color ease .5s;
        }
        &.service-gallery__item--plus {
            .background-square:after {
                background-color: $color-white;
            }
        }
    }
}

.service-gallery__article--link {
    display: block;
    text-align: center;
    min-height: 230px;
}

.service-gallery__article--link.background-square {
    &:after {
        background: $color-white;
        height: 180px;
        width: 180px;
        top: 32px;
        left: 22px;
        right: inherit;
        z-index: 0;
        box-shadow: 7px 9px 6px rgba(0, 0, 0, .2);
    }
    &:before {
        z-index: 1;
        background: $color-5--4;
        height: 170px;
        width: 170px;
        top: 37px;
        left: 27px;
        right: inherit;
        content: '';
        position: absolute;
        transform: rotate(45deg);
        border-radius: 25%;
    }
}

.service-gallery__article--title {
    font-family: $typo-1;
    font-size: 1.3em;
    text-transform: uppercase;
    font-weight: $light;
    color: $color-black;
    position: relative;
    z-index: 3;
    top: 10px;
    transition: color ease .3s;
}

.service-gallery__prev, .service-gallery__next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 101;
    button {
        height: 100%;
        width: 100%;
    }
}

.service-gallery__prev {
    left: 0;
}

.service-gallery__next {
    right: 0;
}

.service-gallery__item--plus {
    display: inline-block;
    position: absolute;
    top: 188px;
    right: 8px;
}
