.events {
    .title-1 {
        text-align: left;
        &:before, &:after {
            content: none;
        }
    }
    .list-square {
        display: none;
        &.square-right {
            display: initial;
        }
    }
    .events__listitem {
        display: block;
    }
    .bloc-event__item {
        margin: 0 5%;
    }
    .link-view__feature {
        right: 5%;
    }
}

.bloc-event__wrapper-content {
    margin-top: 3em;
}
