// @name buttons

// @name button-1 (light)
// @state button-1--big - Big button-1
// @state button-1--small - Small button-1
// @state .button-1--no-icon - Button-1 without icon
// @state .button-1--svg - Button-1 with svg content
// @state .button-1--no-text-in-mobile - Indicates button-1 without content text in smartphone view

$button-1__font-size: 1.4;
$button-1--big__font-size: 1.9;
$button-1--small__font-size: 1.2;
input.button-1, .rte input.button-1 {
    padding-left: em(3.2, $button-1__font-size);
    &.button-1--big {
        padding-left: em(3.2, $button-1--big__font-size);
    }
    &.button-1--small {
        padding-left: em(2.2, $button-1--small__font-size);
    }
}

.button-1, .rte .button-1 {
    font-family: $typo-2;
    display: inline-block;
    vertical-align: middle;
    transition: all ease .3s;
    text-decoration: none;
    font-weight: $normal;
    color: $color-black;
    text-transform: uppercase;
    background: $color-3--1;
    font-size: #{$button-1__font-size}em;
    line-height: em(1, $button-1__font-size);
    padding: em(1.25, $button-1__font-size) em(3.2, $button-1__font-size) em(1.25, $button-1__font-size) em(4.2, $button-1__font-size);
    @include default-icons-absolute-before('\e026', em(1.4, $button-1__font-size), $color-4--2, 50%, inherit, inherit, em(2.2, 1.4));
    overflow: hidden;
    &:before {
        transform: translateY(-50%);
        transition: all ease .3s;
    }
    &[type=reset] {
        &:before {
            content: "\e002";
        }
    }
    &[type=submit] {
        &:before {
            content: "\e057";
        }
    }
    &:hover, &:focus {
        text-decoration: none;
        background: $color-5--3;
        color: color-contrast($color-5--3);
        &:before {
            color: color-contrast($color-5--3);
        }
        > svg {
            fill: color-contrast($color-5--3);
        }
    }
    // Big
    &.button-1--big {
        // Change font size reference
        font-size: #{$button-1--big__font-size}em;
        padding: em(1.9, $button-1--big__font-size) em(4, $button-1--big__font-size) em(1.9, $button-1--big__font-size) em(5, $button-1--big__font-size);
        &:before {
            left: em(2.4, $button-1--big__font-size);
        }
    }
    // Small
    &.button-1--small {
        // Change font size reference
        font-size: #{$button-1--small__font-size}em;
        padding: em(.7, $button-1--small__font-size) em(2.2, $button-1--small__font-size) em(.7, $button-1--small__font-size) em(3.2, $button-1--small__font-size);
        &:before {
            left: em(1.5, $button-1--small__font-size);
        }
    }
    // No icon
    &.button-1--no-icon {
        padding-left: em(2, $button-1__font-size);
        padding-right: em(2, $button-1__font-size);
        &:before {
            content: "";
            margin: 0;
        }
    }
    // No text
    &.button-1--no-text {
        text-align: left;
        text-indent: -9999px;
        white-space: nowrap;
        position: relative;
        padding: em(1.3, $button-1__font-size) em(1.9, $button-1__font-size);
        &:before {
            text-indent: 0;
            line-height: 0;
            display: block;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            margin: 0;
        }
    }
    // With svg
    &.button-1--svg {
        padding: em(1.4, $button-1__font-size) em(1.3, $button-1__font-size);
    }
    > svg {
        fill: $color-4--2;
        width: em(1.2, $button-1__font-size);
        height: em(1.2, $button-1__font-size);
        vertical-align: middle;
    }
    // With calendar font-icon
    &.button-1--calendar {
        &:before {
            left: em(1, 1.4);
            content: "\e05c";
        }
    }
}

//  @name Extenral link for button-1
a.button-1[href^="http://"]:not([href*="#{$domain}"]),
a.button-1[href^="https://"]:not([href*="#{$domain}"]),
a.button-1[href^="//"]:not([href*="#{$domain}"]) {
    @include default-icons-after('\e086', 0 0 0 5px, 8px, $color-black, middle);
    &:after {
        transition: all ease .3s;
    }
    &:hover, &:focus {
        &:after {
            color: $color-black;
        }
    }
}

// @name button-2 (Dark)
// @state button-2--big - Big button-2
// @state button-2--small - Small button-2
// @state .button-2--no-icon - Button-2 without icon
// @state .button-2--svg - Button-2 with svg content
// @state .button-2--no-text-in-mobile - Indicates button-2 without content text in smartphone view

$button-2__font-size: 1.4;
$button-2--big__font-size: 1.9;
$button-2--small__font-size: 1.2;
input.button-2, .rte input.button-2 {
    padding-left: em(3.2, $button-2__font-size);
    &.button-2--big {
        padding-left: em(3.2, $button-2--big__font-size);
    }
    &.button-2--small {
        padding-left: em(2.2, $button-2--small__font-size);
    }
}

.button-2, .rte .button-2 {
    font-family: $typo-2;
    display: inline-block;
    vertical-align: middle;
    transition: all ease .3s;
    text-decoration: none;
    font-weight: $bold;
    color: $color-white;
    text-transform: uppercase;
    background: $color-5--3;
    font-size: #{$button-2__font-size}em;
    line-height: em(1, $button-2__font-size);
    padding: em(1.25, $button-2__font-size) em(3.2, $button-2__font-size) em(1.25, $button-2__font-size) em(4.2, $button-2__font-size);
    @include default-icons-absolute-before('\e057', em(1.4, $button-2__font-size), $color-white, 50%, inherit, inherit, em(2.2, 1.4));
    overflow: hidden;
    &:before {
        transform: translateY(-50%);
        transition: all ease .3s;
    }
    &:hover, &:focus {
        text-decoration: none;
        background: darken($color-5--3, 10%);
        color: $color-3--7;
        > svg {
            fill: $color-3--7;
        }
    }
    // Big
    &.button-2--big {
        // Change font size reference
        font-size: #{$button-2--big__font-size}em;
        padding: em(1.9, $button-2--big__font-size) em(4, $button-2--big__font-size) em(1.9, $button-2--big__font-size) em(5, $button-2--big__font-size);
        &:before {
            left: em(2.4, $button-2--big__font-size);
        }
    }
    // Small
    &.button-2--small {
        // Change font size reference
        font-size: #{$button-2--small__font-size}em;
        padding: em(.7, $button-2--small__font-size) em(2.2, $button-2--small__font-size) em(.7, $button-2--small__font-size) em(3.2, $button-2--small__font-size);
        &:before {
            left: em(1.5, $button-2--small__font-size);
        }
    }
    // No icon
    &.button-2--no-icon {
        padding-left: em(2, $button-2__font-size);
        padding-right: em(2, $button-2__font-size);
        &:before {
            content: "";
            margin: 0;
        }
    }
    // No text
    &.button-2--no-text {
        text-align: left;
        text-indent: -9999px;
        white-space: nowrap;
        position: relative;
        padding: em(1.3, $button-2__font-size) em(1.9, $button-2__font-size);
        &:before {
            text-indent: 0;
            line-height: 0;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            margin: 0;
            font-size: em(1.4, $button-2__font-size);
        }
    }
    // With svg
    &.button-2--svg {
        padding: em(1.4, $button-2__font-size) em(1.3, $button-2__font-size);
    }
    > svg {
        fill: $color-white;
        width: em(1.2, $button-2__font-size);
        height: em(1.2, $button-2__font-size);
        vertical-align: middle;
    }
    // With next icon
    &.button-2--next {
        &:before {
            content: "\e027";
        }
    }
    // With calendar font-icon
    &.button-2--calendar {
        &:before {
            left: em(1, 1.4);
            content: "\e05c";
        }
    }
}

//  @name Extenral link for button-2
a.button-2[href^="http://"]:not([href*="#{$domain}"]),
a.button-2[href^="https://"]:not([href*="#{$domain}"]),
a.button-2[href^="//"]:not([href*="#{$domain}"]) {
    @include default-icons-after('\e086', 0 0 0 5px, 8px, $color-white, middle);
    &:after {
        transition: all ease .3s;
    }
}

// @name Grey form
// @description Form with grey background
.form--3 {
    // @name button-1 (light)
    .button-1 {
        background: $color-white;
        &:hover, &:focus {
            background: $color-5--3;
            text-decoration: none;
        }
    }
    // @name button-1 (light)
    .button-1 {
        background: $color-white;
        &:hover, &:focus {
            background: $color-5--3;
            text-decoration: none;
        }
    }
}
