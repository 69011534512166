.footer {
    .footer__wrapper-2 {
        flex-wrap: wrap;
    }
    .site-infos {
        display: block;
        flex-basis: 100%;
        &:after {
            content: none;
        }
        .site-infos__wrapper {
            padding-left: 0;
            float: right;
        }
        .site-infos__img-wrap {
            left: 40%;
        }
        .info-contact{
            top: 120px;
            left: 20%;
        }
    }
    .menu-cross {
        flex-basis: 50%;
        margin-top: 20px;
        ul {
            margin-left: 30%;
        }
    }
    .newsletter {
        margin-top: 20px;
        flex-basis: 40%;
        margin-left: 10%;
    }
}
