.news-bloc {
    .bloc-news__item--focus{
        min-height: 300px;
        .bloc-news__wrapper {
            margin-right: 20px;
            max-width: 40%;
        }
        .bloc-news__picture {
           .news--image {
                clip-path: url('#poly-4');
                width: 402px;
                height: 242px;
            }
        }
    }
    .link-view__feature {
        right: 15px;
    }
}

.news-bloc .bloc-news {
    .bloc-news__item.bloc-news__item--focus {
        flex-basis: 94%;
        margin-right: -2%;
    }
}
.news-bloc .bloc-news {
    .bloc-news__item--focus {
        .bloc-news__picture {
            left: -30px;
        }
    }
}

