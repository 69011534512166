// # Global
$domain: "stratis.fr";

// ## Typography
$import-google-font-1: "https://fonts.googleapis.com/css?family=Oswald:300,400,700";
$import-google-font-2: "https://fonts.googleapis.com/css?family=Raleway:300,400,700";

$typo-1: 'Oswald', sans-serif;
$typo-2: 'Raleway', sans-serif;
$typo-3: 'Raleway', sans-serif;

$thin: 100;
$extralight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

// ## Path
$image-bg-url: "../Images/bg/";
$image-fancybox-url: "../Images/fancybox/";
$font-path: "../Fonts/";

// ## Media queries
$smartphone: 767;
$tablet: 1279;

// ## B&w
$color-black: #000;
$color-white: #fff;
$color-red: #fe0000;
$color-green: #278417;

// ## Alert & required
$alert-background: #fcdcde;
$alert-title: #ff000d;
$alert-color: $color-black;

// ## color-1
$color-1--1: #ee5159;
$color-1--2: #ca000a;
$color-1--3: #890007;

// ## color-2
$color-2--1: #00b9d8;
$color-2--2: #009fba;
$color-2--3: #006d9b;

// ## Color-3
$color-3--1: #f6f6f6;
$color-3--2: #c5c5c5;

$color-3--3: #727272;
$color-3--4: #efeff3;

$color-3--5: #e0e0e7;
$color-3--6: #49475b;
$color-3--7: #282731;
$color-3--8: #474558;

// ## Color-4
$color-4--1: #384d48;
$color-4--2: #5c805a;
$color-4--3: #8aad75;
$color-4--4: #b4ca94;

// ## Color-5
$color-5--0: #feebc9;
$color-5--1: #fdd692;
$color-5--2: #ffb852;
$color-5--3: #ec7357;
$color-5--4: #ffe7bc;

//// ## Color-6 social network
$color-6--1: #3b5998;
$color-6--2: #00acee;
$color-6--3: #4c68d7;
$color-6--4: #c4302b;
