body.menu-main-1--active {
    overflow-y: hidden;
    .menu-main-1__listitems-0 {
        > .menu-main-1__item {
            overflow-y: auto;
            height: 100%;
        }
    }
}

.menu-main-1__item--no-action-in-desktop {
    .menu-main-1__sublevel {
        display: none;
        &[aria-hidden="false"] {
            display: block;
        }
    }
}

/* Styles */

// All level
.menu-main-1__wrapper {
    z-index: 100;
    position: static;

}

.menu-main-1 {
    .wrapper-main {
        width: 100%;
        padding: 0;
        margin: 0;
    }
}

// Level 0 (displayed in tablet and smartphone)
.menu-main-1__listitems-0 {
    > li {
        //position: absolute;
        position: fixed;
        z-index: 101;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        transition: transform ease .3s;
        transform: translate(100%, 0);
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        > .menu-main-1__header {
            display: block;
            background: $color-white;
            padding: 2.2em 0;
            border-bottom: 1px solid $color-black;
            background-image: url("../Images/background/bg-3.png");
            background-repeat: no-repeat;
            background-position-x: -230px;
            background-position-y: -130px;
            > span {
                display: block;
                text-transform: uppercase;
                font-size: 1.9em;
                color: #384d48;
                padding: 0 0 0 4.5rem;
            }
            .menu-main-1__action {
                text-align: left;
                text-indent: -9999px;
                white-space: nowrap;
                position: absolute;
                top: 10px;
                left: -60px;
                width: 4rem;
                height: 4rem;
                background: $color-5--1;
                transition: left ease .3s, top ease .3s;
                border-radius: 12px;
                &:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%);
                    font-family: icons-default;
                    content: "\e02f";
                    font-size: 1.2em;
                    font-weight: $bold;
                    text-indent: 0;
                    line-height: 0;
                    display: block;
                    margin: 0;
                    color: $color-3--7;
                }
            }
        }
        > .menu-main-1__sublevel {
            display: none;
            &[aria-hidden="false"] {
                display: block;
            }
            > .menu-main-1__closing-button {
                display: block;
                top: 1.1em;
                right: 0;
                &:before {
                    font-size: 1.8em;
                }
            }
        }
        &.menu-main-1__item--active {
            transform: translate(0, 0);
            > .menu-main-1__header {
                .menu-main-1__action {
                    left: 0;
                    top: 1.1em;
                    background: transparent;
                    &:before {
                        color: $color-4--2;
                    }
                }
            }
        }
    }
}

// Level 1
.menu-main-1__listitems-1 {
    background: $color-white;
    > li {
        display: block;
        vertical-align: inherit;
        padding: 15px 0;
        border-bottom: 1px solid $color-4--3;
        > .menu-main-1__header {
            > a {
                color: $color-3--7;
                padding: 1.1em;
                position: relative;
                padding-left: 3em;
                &:after {
                    position: absolute;
                    top: 50%;
                    right: .5rem;
                    transform: translateY(-91%) rotate(45deg) translateX(71%);
                    font-size: 1.3em;
                    font-weight: $light;
                    display: block;
                }
                &:before {
                    left: 17px;
                    transform: translateY(-41%);
                }
                &[aria-expanded="true"] {
                    &:before {
                        content: "\e024";
                        left: 16px;
                    }
                }
            }
        }
        > .menu-main-1__sublevel {
            position: static;
            max-height: inherit;
            display: none;
            background: $color-4--3;
            .menu-main-1__listitems-1__wrapper {

                transform: inherit;
                height: auto;
                padding: 0;
            }
            &[aria-hidden="false"] {
                display: block;
                max-height: inherit;
                background: $color-4--3;
                border-radius: 0;
                .menu-main-1__listitems-1__wrapper {
                    transform: inherit;
                    transition: inherit;
                }
            }
        }
    }
    .menu-main-1__item--current, .menu-main-1__item--active {
        .background-square:after {
            background: $color-4--3;
        }
    }
    .menu-main-1__closing-button {
        display: none;
    }
}

// Level 2
.menu-main-1__listitems-2 {
    width: 100%;
    border-radius: 0;
    > li {
        // Level 2 and 3
        padding: 1em;
        border-radius: 0;
        //background: $color-4--3;
        border-bottom: none;
        .menu-main-1__header {
            .menu-main-1__action {
                &:before {
                    content: "\e027";

                }
                &[aria-expanded="true"] {
                    &:before {
                        content: "\e024";
                        left: 47%;
                    }
                }
            }
        }
        > .menu-main-1__header {
            a {
                padding-top: .5em;
                padding-bottom: .5em;
                padding-left: 1.2em;
                &:before {
                    left: 4px;
                    transform: translateY(-50%);
                }
            }
        }
        > .menu-main-1__sublevel {
            display: none;
            position: static;
            width: 100%;
            transform: inherit;
            height: 0;
            transition: inherit;
            background: $color-4--3;
            &[aria-hidden="false"] {
                display: block;
                transform: inherit;
                height: auto;
                transition: inherit;
                background: $color-4--3;
                border-radius: 12px;
            }
        }
    }
    .menu-main-1__item--current, .menu-main-1__item--active {
        .menu-main-1__header {
            a {
                &:before {
                    left: 4px;
                    //transform: translateY(-80%);
                }
            }
        }
    }
}

// Level 3
.menu-main-1__listitems-3 {
    padding: .5em 0;
    > li {
        padding: 0 1em;
        > .menu-main-1__header {
            a {
                padding-top: .4em;
                padding-bottom: .4em;
                padding-left: 2em;
            }
            .menu-main-1__action {
                left: -16px;
                right: inherit;
                &[aria-expanded="true"] {
                    &:before {
                        content: "\e014";
                    }
                }
                &:before {
                    z-index: 3;
                    content: "\e017";
                    color: $color-5--1;
                }
                &:after {
                    background: $color-4--2;
                    z-index: 2;
                }
            }
        }
        > .menu-main-1__sublevel {
            display: none;
            position: static;
            width: 100%;
            transform: inherit;
            height: 0;
            transition: inherit;
            background: $color-4--2;
            &[aria-hidden="false"] {
                display: block;
                transform: inherit;
                height: auto;
                transition: inherit;
            }
        }
    }
    .menu-main-1__item--current, .menu-main-1__item--active {
        background-color: $color-4--3;

    }
}

// Level 4
.menu-main-1__listitems-4 {
    padding: .5em 0;
    > li {
        padding: 0 2em;
        > .menu-main-1__header {
            a {
                padding-top: .3em;
                padding-bottom: .3em;
                padding-left: 1.7em;
                color: $color-3--7;
            }
            .menu-main-1__action {
                left: -16px;
                right: inherit;
                &[aria-expanded="true"] {
                    &:before {
                        content: "\e014";
                    }
                }
                &:before {
                    z-index: 3;
                    content: "\e017";
                    color: $color-5--1;
                }
                &:after {
                    background: $color-4--1;
                    z-index: 2;
                }
            }
        }
        > .menu-main-1__sublevel {
            display: none;
            position: static;
            width: 100%;
            transform: inherit;
            height: 0;
            transition: inherit;
            background: $color-4--1;
            &[aria-hidden="false"] {
                display: block;
                transform: inherit;
                height: auto;
                transition: inherit;
            }
        }
    }
    .menu-main-1__item--current, .menu-main-1__item--active {
        background-color: $color-4--2;
        > .menu-main-1__header {
            a {
                color: $color-3--7;
            }
        }
    }
}

// Level 5
.menu-main-1__listitems-5 {
    > li {

    }
    .menu-main-1__item--current, .menu-main-1__item--active {
        background-color: $color-4--1;
        > .menu-main-1__header {
            a {
                color: $color-white;
            }
        }
    }

}

.home {
    .menu-main-1__listitems-2 {
        width: 100%;
    }
    .menu-main-1__listitems-2 > li > .menu-main-1__sublevel {
        width: 100%;
    }
    .menu-main-1__listitems-3 > li > .menu-main-1__sublevel {
        width: 100%;
    }
    .menu-main-1__listitems-4 > li > .menu-main-1__sublevel {
        width: 100%;
    }

}


