.footer {
    .svg-city {
        width: 180px;
        top: -49px;
        left: -1px;
    }

    .site-infos {
        padding-bottom: 30px;
        &:after {
            content: '';
            height: 1px;
            width: 80%;
            top: unset;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
        .site-infos__wrapper {
            float: none;
            width: 100%;
            text-align: center;
            padding-top: 50px;
        }
        .site-infos__img-wrap {
            left: 53%;
            &:after {
                right: 10px;
                height: 140px;
                width: 140px;
            }
        }
        .list-infos__item:before {
            display: inline-block;
            position: relative;
            margin-right: 3px;
        }
        .info-contact {
            position: absolute;
            top: 120px;
            left: 15%;
        }
    }
    .menu-cross {
        padding-bottom: 30px;
        padding-top: 10px;
        &:after {
            content: "";
            height: 1px;
            width: 80%;
            top: unset;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
        text-align: center;
        ul {
            margin-left: 0;
            li {
                &:before {
                    position: relative;
                    display: inline-block;
                }
                a {
                    display: inline-block;
                }
                &:last-child:after {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
    .menu-cross, .newsletter {
        display: block;
        width: 100%;
    }
    .newsletter {
        padding-top: 10px;
        text-align: center;
        form {
            display: inline-block;
            text-align: center;
        }
    }
}

.site-infos {
    .site-infos__img-wrap {
        img {
            width: 80%;
        }
        &:after {
            top: -10px;
            right: 2px;
            height: 150px;
            width: 150px;
        }
    }
}

