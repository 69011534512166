// @name Title 1
// @description Styling for title 1
$title--1__font-size: 3.5;
.title-1 {
    font-family: $typo-1;
    font-size: #{$title--1__font-size}em;
    line-height: 1em;
    font-weight: $light;
    text-transform: uppercase;
    margin: em(3, $title--1__font-size) 0 em(2, $title--1__font-size);
    position: relative;
    text-align: center;
    margin-bottom: 0;
    &:before, &:after {
        content: '';
        display: inline-block;
        height: 1px;
        width: 225px;
        background-color: $color-3--3;
        position: relative;
        top: -13px;
    }
    &:before {

    }
    &:after {

    }
    .list-square {
        background-color: transparent;
        padding: 5px 5px;
        position: relative;
        left: inherit;
        top: -10px;
        .square-1 {
            margin-right: 15px;
        }
        &.square-right {
            .square-1 {
                margin-right: 5px;
            }
            .square-2 {
                margin-right: 15px;
            }
        }
    }
}

.title-1__wrapper {
    &:after {
        content: "";
        background-image: url('../Images/background/shadow-title.png');
        background-repeat: no-repeat;
        position: relative;
        top: -15px;
        left: 50%;
        transform: translateX(-50%);
        height: 60px;
        width: 100px;
        display: block;
    }
}

// @name Title 2
// @description Styling for title 2
$title--2__font-size: 1.8;
.title-2 {
    font-family: $typo-3;
    font-size: #{$title--2__font-size}em;
    font-weight: $normal;
    text-transform: uppercase;
    margin: em(2.5, $title--2__font-size) 0 em(1.5, $title--2__font-size);
}

// @name Title 3
// @description Styling for title 3
$title--3__font-size: 2.6;
.title-3 {
    font-family: $typo-1;
    font-size: #{$title--3__font-size}em;
    line-height: 1em;
    font-weight: $light;
    text-transform: uppercase;
    color: $color-3--7;
    margin: em(2.5, $title--3__font-size) 0 em(2, $title--3__font-size);
    padding-bottom: em(1.6, $title--3__font-size);
    border-bottom: 1px solid $color-3--2;
    text-align: center;
}
