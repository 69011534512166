// @name Main section heading
// @description Default css
$heading__h1__font-size: 4.5;
.heading {
    background-image: url('../Images/background/bg-0.png');
    padding: 4em 0 6em;
    position: relative;
    .headding__wrapper-title {
        @extend .wrapper-main;
        margin-bottom: 2em;
    }
    .heading__wrapper {
        @extend .wrapper-main;
        display: flex;
        flex-wrap: nowrap;
        > *:last-child {
            margin-bottom: 0;
        }
        .heading__wrapper-figure {
            flex-basis: auto;
        }
        .heading__wrap {
            flex-basis: 100%;
        }
    }
    .heading__figure {
        margin-right: 2em;
        display: table;
    }
    .heading__picture {
        display: block;
        margin-bottom: .5em;
        img {
            //max-width: 100%;
            height: auto;
        }
    }
    .heading__caption {
        display: table-caption;
        caption-side: bottom;
    }
    h1 {
        font-family: $typo-1;
        font-weight: $light;
        font-size: #{$heading__h1__font-size}em;
        line-height: 1.3;
        color: $color-black;
        text-transform: uppercase;
    }
    h1 + .teaser-2 {
        margin-top: em(2.4, $teaser-2__font-size);
    }
    .subtitle {
        $category__font-size: 1.8;
        font-size: #{$category__font-size}em;
    }
    .subtitle + h1 {
        margin-top: 5px;
    }
    .date {
        font-size: 1.4em;
    }
    .status {
        $status__font-size: 1.5;
        background: #fff;
        font-size: #{$status__font-size}em;
        margin-top: em(0.5, $status__font-size);
        display: inline-block;
        &.status--new {
            background: $color-1--2;
            color: $color-white;
            &:before {
                content: "\e093";
                color: $color-white;
            }
        }
        &.status--in-progress {
            background: $color-2--2;
            color: $color-white;
            &:before {
                content: "\e093";
                color: $color-white;
            }
        }
    }
    .heading__media {
        margin-top: 1em;
        padding-top: 1em;
        border-top: 1px solid darken($color-3--1, 20%);
        .date, .media {
            font-size: 1.6em;
        }
        .media {
            font-weight: $bold;
            border: none;
            & + .date {
                &:before {
                    content: " - ";
                    display: inline;
                    font-size: 1.2em;
                    color: $color-3--3;
                }
            }
        }
    }
    // @name picture to right
    &.heading--picture-right {
        .heading__wrapper {
            .heading__wrapper-figure {
                order: 2;
            }
            .heading__wrap {
                order: 1;
            }
        }
        .heading__figure {
            margin-right: 0;
            margin-left: 2em;
        }
    }
}

// @name Heading single news
// @dependency .heading
.heading--single-news {
    .heading__picture {
        img {
            width: 280px;
            max-width: inherit;
            height: auto;
        }
    }
    .category {
        a {
            color: $color-4--2;
        }
    }
}

// @name Heading single event
// @dependency .heading
.heading--single-event {
    .heading__wrapper {
        display: block;
        flex-wrap: inherit;
    }
    .heading__figure {
        margin-right: 0;
    }
    .heading__wrapper-figure, .heading__wrapper-date, .heading__wrap {
        display: table-cell;
        vertical-align: top;
    }
    .heading__picture {
        img {
            width: 280px;
            max-width: inherit;
            height: auto;
        }
    }
    .heading__wrapper-date {
        width: 23em;
        border-right: 1px solid $color-3--3;
        .date-1 time {
            position: relative;
            top: -50px;
        }
    }
    .heading__wrapper-calendar-button {
        margin: 1em 1em 0;
        line-height: normal;
    }
    .heading__wrap {
        padding-left: 2em;
    }
}

// @name Heading single publications
// @dependency .heading
.heading--single-publications, .heading--single-public-market {
    .heading__picture {
        img {
            width: 220px;
            max-width: inherit;
            height: auto;
        }
    }
}

// @name Heading single directory
// @dependency .heading
.heading--single-directory {
    .list-infos {
        margin-top: 2em;
    }
    .list-infos__wrapper {
        .list-infos__listitems:first-child {
            flex: 1 1 0;
        }
    }
    .list-infos__item {
        padding-left: em(2, 1.6);
        font-size: 1.6em;
    }
    .contact__info {
        margin-top: 30px;
        .list-infos__phone, .list-infos__fax {
            display: inline-block;
            margin-right: 50px;
        }
    }
}

.header__middle__go-to-main {
    position: absolute;
    bottom: -60px;
    right: 0;
    left: 0;
    height: 60px;
    overflow: hidden;
    .button-2 {
        height: 120px;
        width: 120px;
        border-radius: 20%;
        position: absolute;
        left: 50%;
        top: -33px;
        z-index: 2;
        transform: rotate(45deg) translateX(-50%);
        background-color: $color-white;
        overflow: hidden;
        &:before {
            content: '\e014';
            color: $color-black;
            left: 72px;
            top: 84px;
            font-size: 1.8em;
            z-index: 8;
            transform: rotate(-45deg);
        }
        &:after {
            content: '';
            display: block;
            height: 150px;
            width: 150px;
            position: absolute;
            top: -1px;
            left: 0;
            background-image: url('../Images/background/bg-0.png');
            transform: rotate(-45deg);
        }
    }
}
