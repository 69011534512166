// @name bloc publications

// @name Bloc publications in column

.bloc-publications {
    position: relative;
    font-family: $typo-1;
    .title-3 {
        margin-bottom: 30px;
    }
    a:hover {
        color: $color-5--3;
    }
    .list-square {
        left: 50%;
        .square-1 {
            background: $color-5--3;
        }
        .square-2 {
            background: $color-5--2;
        }
    }
    .link-view {
        margin-top: 15px;
    }
    .link-publications {
        font-size: 1.4em;
        font-weight: $normal;
        text-transform: uppercase;
        color: $color-3--7;
        position: relative;
        &:before {
            content: '';
            background: $color-3--6;
            height: 22px;
            width: 22px;
            display: inline-block;
            border-radius: 20%;
            margin-right: 15px;
            transform: rotate(45deg);
            position: relative;
            top: 4px;
        }
        &:after {
            font-family: icons-default;
            content: '\e034';
            display: inline-block;
            color: $color-white;
            font-size: 0.8em;
            position: absolute;
            left: 6px;
            top: 3px;
        }
    }
    .button-2 {
        box-shadow: 4px 3px 6px $color-3--2;
    }
    .list-document-1__item--download,  .list-document-1__item--read {
        display: inline-block;
        &:before {
            content: none;
        }
        .button-2 {
            transform: rotate(45deg);
            border-radius: 20%;
            &:before {
                transform: rotate(-45deg) translateX(-50%) translateY(-50%);
            }
        }
    }
    .list-document-1__listitems {
        text-align: center;
    }
    .list-document-1__item--download {
        margin-right: 10px;
        .button-2 {
            height: 70px;
            width: 70px;
            &:before {
                content: '\e063';
                left: 40px;
                top: 27px;
            }
        }
    }
    .file-info {
        font-size: 1.25em;
        font-weight: $light;
        text-transform: uppercase;
        text-align: center;
        display: block;
        cursor: pointer;
        margin-bottom: 1em;
    }
    .list-document-1__item--read {
        .button-2 {
            background: $color-5--1;
            height: 55px;
            width: 55px;
            margin-top: 60px;
            &:before {
                content: '\e06e';
                color: $color-black;
                left: 32px;
                top: 20px;
            }
            &:hover {
                background: darken($color-5--1, 10%);
            }
        }
    }
}

.bloc-publications__picture {
    display: block;
    float: left;
    margin-bottom: 25px;
    img {
        transform: rotate(-5deg);
        box-shadow: 4px 4px 8px $color-3--2;
        position: relative;
        left: 0;
        top: 0;
    }
}

.bloc-publications__wrapper {
    overflow: hidden;
}

.bloc-publications__title {
    $bloc-publications__title__font-size: 2.1;
    font-size: #{$bloc-publications__title__font-size}em;
    font-family: $typo-1;
    text-transform: uppercase;
    text-align: center;
    font-weight: $normal;
    margin: 0 0 em(.8, $bloc-publications__title__font-size);
    color: $color-black;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-1--2;
        }
    }
}

.column-bloc {
    .bloc-publications__title {
        $bloc-publications__title__font-size: 1.6;
        font-size: #{$bloc-publications__title__font-size}em;
        margin: 0 0 em(.8, $bloc-publications__title__font-size);
    }
}
