.image-bloc {
    .image-bloc__listitem {
        margin: 0 0;
    }
    .image-bloc__item {
        flex-basis: 47%;
        margin-bottom: 5%;
        box-shadow: none;
        a {
            display: block;
            width: 100%;
            overflow: visible;
            position: relative;
            &:hover, &:focus {
                .item__content {
                    top: unset;
                }
                .content-title {
                    border-bottom: 1px solid black;
                }
            }
        }
    }
    .item___img {
        img {
            box-shadow: 5px 8px 15px rgba(0, 0, 0, 0.4);
        }
    }
    .item__content {
        position: relative;
        background: none;
        min-height: unset;
        .category {
            margin-top: 10px;
        }
        .button-1 {
            display: none;
        }
    }
}

.image-bloc .image-bloc__item.image-bloc__listitems--small {
    display: none;
}
