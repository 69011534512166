.join-us {
    padding-top: 6em;
    text-align: left;
    &__wrapper {
        justify-content: center;
        flex-wrap: wrap;
    }
    &__title {
        width: 100%;
        margin: 0 0 1em;
    }
    .tools {
        margin: 0;
    }
    .background-square {
        margin: 0 1em;
    }
}
