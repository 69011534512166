// @name bloc news

// @name Bloc news in column
.bloc-news {
    position: relative;
    .list-square {
        .square-1 {
            background: $color-4--2;
        }
        .square-2 {
            background: $color-4--3;
        }
        .square-3 {
            background: $color-4--4;
        }
    }
    .bloc-news__picture {
        display: block;
    }
    img {
        width: 100%;
        height: auto;
        margin-bottom: 2em;
        border-radius: 5%;
        box-shadow: 4px 3px 8px rgba(0, 0, 0, 0.2);
    }
    .link-bloc__context {
        z-index: 0;
    }
    .bloc-news__item {
        background: $color-4--1;
        border-radius: 5%;
        padding-bottom: 35px;
    }
    .category {
        text-align: center;
        font-size: 1.4em;
        color: $color-5--1;
    }
    .bloc-news__title {
        $bloc-news__title__font-size: 2;
        font-family: $typo-1;
        font-weight: $normal;
        text-transform: uppercase;
        font-size: #{$bloc-news__title__font-size}em;
        margin: 0 0 em(1.5, $bloc-news__title__font-size);
        color: $color-white;
        text-align: center;
        a {
            color: $color-white;
            &:hover, &:focus {
                color: $color-4--3;
            }
        }
    }
    .button-2 {
        transform: rotate(45deg) translateX(-50%);
        border-radius: 20%;
        height: 38px;
        width: 38px;
        top: -8px;
        left: 50%;
        background: $color-4--3;
        box-shadow: 4px 3px 6px $color-3--2;
        position: relative;
        z-index: 1;
        &:before {
            content: '\e000';
            left: 20px;
            transform: rotate(-45deg) translateY(-76%);
        }
        &:hover {
            background: $color-4--1;
        }
    }
}
