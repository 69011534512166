/*
.list-type-1--event {
  .list-type-1__picture {
    img {
      margin-right: 2em;
    }
  }
  .list-type-1__wrapper-date {

    display: table-cell;
    vertical-align: top;
    width: 13em;
    border-bottom: 0;
    margin-bottom: 0;
    .date-1, .hour-place {
      width: auto;
      display: block;
      vertical-align: inherit;
      padding-bottom: 0;
    }
    .date-1 {
      text-align: center;
      time:nth-of-type(1) {
        margin-left: 1em;
      }
    }
  }
  .list-type-1__wrapper {
    padding-left: 2em;
  }
}
*/