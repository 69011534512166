// @name button-1 (light)
// @state .button-1--no-text-in-mobile - Indicates button-1 without content text in mobile view

.button-1, .rte .button-1 {
    // @name button-1 without content text in mobile view
    &.button-1--no-text-in-mobile {
        text-align: left;
        text-indent: -9999px;
        white-space: nowrap;
        position: relative;
        padding: em(1.3, $button-1__font-size) em(2.1, $button-1__font-size);
        &:before {
            text-indent: 0;
            line-height: 0;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            margin: 0;
            font-size: em(1.4, $button-1__font-size);
        }
    }
}

// @name button-2 (Dark)
// @state .button-2--no-text-in-mobile - Indicates button-2 without content text in mobile view

.button-2, .rte .button-2 {
    // @name button-1 without content text in mobile view
    &.button-2--no-text-in-mobile {
        text-align: left;
        text-indent: -9999px;
        white-space: nowrap;
        position: relative;
        padding: em(1.3, $button-2__font-size) em(2.1, $button-2__font-size);
        &:before {
            text-indent: 0;
            line-height: 0;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            margin: 0;
            font-size: em(1.4, $button-2__font-size);
        }
    }
}
