// @name Background-in-wrapper
// @description Displayed in grey background on the sides.
.background-in-wrapper {
    margin: 0 -1em;
    padding: 0 1em;
    &:before, &:after {
        width: 0;
    }
}

// @name Column bloc
// @description Generic bloc in .section-main__aside
.column-bloc {
    width: 100%;
    padding-left: 0;
}

//header wrapper top
.header__top__wrapper {
    .img-wrap {
        width: unset;
    }
    .social-networks {
        left: 5px;
        top: 35px;
    }
}

.home .header {
    padding-top: 10px;
    margin-top: 0;
    height: 70px;
    &:before {
        top: 0;
    }
}

.header {
    padding-top: 0;
    height: 70px;
    margin-top: 0;
    &:before {
        top: 0;
        width: 60px;
    }
}
