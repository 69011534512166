// Galerie classique (avec vignettes) + Galerie album

// Galerie classique (avec vignettes) + Galerie album
.classical-gallery {
    position: relative;
    &__title {
        padding: 0 10px;
    }
    &__nb-items {
        display: block;
        padding: 0 10px;
        @include background-opacity($color-black, .8);
        color: $color-white;
        position: absolute;
        z-index: 10;
        bottom: 0;
        right: 0;
        font-size: 3em;
        margin: 0;
    }
}

.slider-galerie {
    margin-bottom: 0;
    &__item {
        height: auto;
        display: none;
        &:first-child {
            display: block;
        }
    }
    .infos-img {
        display: none;
        position: static;
        right: inherit;
        bottom: inherit;
        background: #000;
    }
    a {
        display: inline-block;
        text-decoration: none;
        padding: 0;
    }
    img {
        max-height: inherit;
        position: static;
        top: inherit;
        bottom: inherit;
        left: inherit;
        right: inherit;
        transform: inherit;
        display: inline;
    }
}

.carousel-galerie__thumb, .carousel-galerie__pager {
    display: none;
}

.carousel-1__control .button-stop {
    height: 30px;
    width: 30px;
}
