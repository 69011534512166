//toplinks
.links-menu {
    position: fixed;
    top: 40vh;
    right: 0;
    z-index: 200;
    opacity: 0;
    visibility: hidden;
    transition: opacity ease-in-out 1s;
    -webkit-transform: translateZ(0);
    &:after {
        content: '';
        clear: both;
        display: table;
    }
    &-wrap {
        width: 320px;
        height: 90px;
        right: 0;
        position: absolute;
        //overflow: hidden;
        pointer-events: none;
        &:nth-child(1) {
            top: 0;
            .background-square:after {
                top: 8px;
            }
            .wrapper-img {
                top: 12px;
            }
            .text {
                margin-top: -14px;
            }

        }
        &:nth-child(2) {
            top: 80px;
        }
        &:nth-child(3) {
            top: 150px;
        }
        &:nth-child(4) {
            top: 220px;
        }
        &:nth-child(5) {
            top: 290px;
        }
        &:nth-child(6) {
            top: 360px;
        }
        &:nth-child(7) {
            top: 430px;
        }
        &:nth-child(8) {
            top: 500px;
        }
        &:nth-child(9) {
            top: 570px;
        }
        &:nth-child(10) {
            top: 640px;
        }
    }
    &-item {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        transition: .3s;
        pointer-events: auto;
        > div {
            overflow: hidden;
        }
        &:hover,
        &:focus {
            .text {
                right: 108%;
                visibility: visible;
                opacity: 1;
            }
            .wrapper-img {
                background-position-x: 0;
            }
            img {
                opacity: 0;
            }
        }
        .wrapper-img {
            position: relative;
            display: block;
            background-repeat: no-repeat;
            background-size: 100%;
            max-width: 20px;
            background-position-x: 1000px;
            background-position-y: 100%;
            left: 30px;
            top: 5px;
            img {
                max-width: 20px;
                height: auto;
            }
        }

        .img-icon {
            display: block;
            width: 70px;
            height: 90px;
            line-height: 45px;
            text-align: center;
            position: relative;
            z-index: -1;
        }
        .background-square {
            &:after {
                width: 80px;
                height: 80px;
                right: -30px;
                background: #3F3D4E;
                border-radius: 25%;
            }

        }
        .text {
            display: block;
            min-width: 115px;
            height: 32px;
            line-height: 32px;
            white-space: nowrap;
            padding: 0 5px 0 10px;
            margin-top: -20px;
            text-align: center;
            right: -100%;
            visibility: hidden;
            opacity: 0;
            top: 50%;
            font-family: $typo-1;
            font-size: 1.3em;
            font-weight: $bold;
            text-transform: uppercase;
            transition: all .3s ease;
            color: $color-black;
            background-color: $color-5--1;
            position: absolute;
            z-index: 10;
            &:after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 16px 0 16px 17px;
                border-color: transparent transparent transparent #fdd692;
                position: absolute;
                top: 0;
                right: -17px;
            }
        }
    }
}
