.events {
    padding-top: 8em;
    padding-bottom: 8em;
    background-color: $color-3--7;
    position: relative;
    a {
        color: inherit;
    }
    .wrapper-main {
        position: relative;
    }
    .title-1 {
        color: $color-white;
        &:before, &:after {
            background: $color-3--6;
        }
    }
    .list-square {
        .square-1 {
            background-color: $color-5--1;
        }
        .square-2 {
            background-color: $color-5--2;
        }
        .square-3 {
            background-color: $color-5--3;
        }
        &.square-right {
            .square-1 {
                background-color: $color-5--3;
            }
            .square-3 {
                background-color: $color-5--1;
            }
        }
    }
    .link-view__feature {
        position: absolute;
        top: 0;
        right: 0;
        .button-1 {
            background: $color-3--6;
            border-radius: 20%;
            transform: rotate(45deg);
            height: 45px;
            width: 45px;
            svg {
                fill: $color-white;
                transform: rotate(-45deg);
                top: 1px;
                left: 5px;
                position: relative;
            }
            &:hover {
                background: $color-5--3;
            }
        }
    }
    .events__listitem {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin: 0 -2%;
        &:hover {
            .bloc-event__picture img {
                filter: grayscale(100%);
            }
        }
    }
    .bloc-event__item {
        flex-basis: 23%;
        margin: 0 1%;
        margin-bottom: 2%;
        &:hover {
            .bloc-event__picture img {
                filter: grayscale(0);
            }
        }
        a {
            display: block;
        }
    }
    .bloc-event__picture {
        display: block;
        img{
            width: 100%;
            height: auto;
            border-radius: 3%;
        }
    }
    .bloc-event__wrapper {
        top: 50%;
        transform: translateY(-50%);
    }
    .bloc-event__picture + .bloc-event__wrapper {
        top: 0;
        transform: none;
    }
    .category {
        font-family: $typo-1;
        font-size: 1.4em;
        color: $color-5--1;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-right: 65%;
    }
    .date-1 {
        position: absolute;
        top: 82px;
        right: -7px;
    }
    .bloc-event__title {
        font-family: $typo-1;
        font-size: 2em;
        text-transform: uppercase;
        color: $color-white;
        font-weight: $normal;
        margin-bottom: 20px;
        a {
            color: $color-white;
        }
    }
    .hour-place {
        text-align: left;
        margin-bottom: 20px;
        &:before {
            content: '';
            display: block;
            width: 60px;
            height: 3px;
            background: $color-3--6;
            margin-bottom: 10px;
        }
    }
    .hour {
        font-family: $typo-2;
        font-weight: $bold;
        font-size: 1.8em;
        color: #b3b2bc;
    }
    .bloc-event-place {
        font-family: $typo-2;
        font-weight: $light;
        font-size: 1.4em;
        color: #b3b2bc;
    }
    .link-view {
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translateX(-49%);
        z-index: 1;
    }
    .button-2 {
        height: 65px;
        width: 65px;
        transform: rotate(45deg) translateX(-50%);
        border-radius: 20%;
        background-color: $color-5--3;
        box-shadow: 4px 3px 6px rgba(0, 0, 0, 0.5);
        left: 50%;
        position: absolute;
        top: -65px;
        &:hover, &:focus {
            background-color: $color-3--6;
        }
        &:before {
            transform: translateY(-50%) translateX(-50%) rotate(-45deg);
            content: '\e000';
        }
    }
}
