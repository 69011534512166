// @name bloc contact

// @name Bloc contact in column
.column-bloc.bloc-contact {
    position: relative;
    a {
        text-decoration: none;
        font-weight: 400;
        &:hover {
            text-decoration: underline;
        }
    }
    .list-square {
        left: 50%;
        .square-1 {
            background: $color-5--3;
        }
        .square-2 {
            background: $color-5--2;
        }
    }
    .service {
        font-family: $typo-1;
        font-size: 1.8em;
        font-weight: $normal;
        text-align: center;
    }
    .bloc-contact__title {
        $bloc-contact__title__font-size: 1.6;
        font-family: $typo-1;
        font-weight: $light;
        font-size: #{$bloc-contact__title__font-size}em;
        color: $color-black;
        text-align: center;
        a {
            color: $color-black;
            &:hover, &:focus {
                color: $color-1--2;
            }
        }
    }
    .list-infos {

    }
    .list-infos__item {
        padding-left: 0;
        &.list-infos__address:before, &.list-infos__phone:before {
            content: none;
        }

    }
    .list-infos__wrapper {
        display: block;
        flex-wrap: inherit;
        justify-content: inherit;
        font-family: $typo-1;
        font-weight: $light;
        text-align: center;
        > * {
            flex: inherit;
        }
        .list-infos__listitems + .list-infos__listitems {
            border-left: 0;
            padding-left: 0;
            margin-left: 0;
        }
    }
    .button-2 {
        margin-top: em(1, $button-2__font-size);
        transform: rotate(45deg);
        border-radius: 20%;
        height: 2em;
        width: 2em;
        left: 25%;
        &:before {
            content: "\e02a";
            transform: translateY(-50%) rotate(-45deg) translateX(-50%);
        }
        &.phone {
            &:before {
                content: '\e02b';
                font-size: 0.7em;
                left: 20px;
                top: 14px;
            }
        }
    }
    .courier {
        font-size: 1.6em;
        font-family: $typo-1;
        text-transform: uppercase;
        font-weight: $light;
        a {
            color: $color-black;
            font-weight: $light;
        }
        .button-2 {
            font-size: 1.225em;
            background: $color-5--1;
            &:before {
                font-size: 0.7em;
                left: 19px;
                top: 16px;
                color: $color-black;
            }
            &:hover {
                background: darken($color-5--1, 10%);
            }
        }
    }
    .contact-wrap {
        display: table;
        border-collapse: separate;
        border-spacing: 20px 0;
        .courier, .list-infos__phone {
            display: table-cell;
            .button-2 {
                display: block;
                margin-bottom: 10px;
            }
        }
    }
}

//News
.column-bloc.bloc-news {
    a {
        color: inherit;
    }
}
