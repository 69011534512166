.image-bloc {
    .title-1 {
        text-align: left;
        &:before, &:after {
            content: none;
        }
    }
    .list-square {
        display: none;
        &.square-right {
            display: initial;
        }
    }
    .image-bloc__listitem {
        display: block;
    }
    .image-bloc__item {
        margin: 0 5%;
    }
    .button-2 {
        top: 30px;
    }
}

.home {
    .image-bloc {
        padding-bottom: 3em;
        .button-2 {
            top: inherit;
        }
    }
}
