// @name Bloc news on home
// @dependency : .bloc-news in _bloc-news.scss
.news-bloc {
    background-image: url('../Images/background/bg-4.png');
    padding-top: 9em;
    position: relative;
    padding-bottom: 5em;
    margin-top: -11em;
    z-index: 1;
    .wrapper-main {
        position: relative;
    }
    .title-1 {
        &:before, &:after {
            background: #b3b2bc;
        }
    }
    .title-1__wrapper:after {
        background-image: url('../Images/background/shadow-title2.png');
    }
    .list-square {
        .square-1 {
            background-color: $color-4--4;
        }
        .square-2 {
            background-color: $color-4--2;
        }
        .square-3 {
            background-color: $color-4--1;
        }
        &.square-right {
            .square-1 {
                background-color: $color-4--1;
            }
            .square-3 {
                background-color: $color-4--4;
            }
        }
    }
    .link-view__feature {
        position: absolute;
        top: 0;
        right: 0;
        .button-1 {
            background: $color-3--5;
            border-radius: 20%;
            transform: rotate(45deg);
            height: 45px;
            width: 45px;
            svg {
                fill: $color-black;
                transform: rotate(-45deg);
                top: 1px;
                left: 5px;
                position: relative;
            }
            &:hover {
                background: $color-4--2;
                svg {
                    fill: $color-white;
                }
            }
        }
    }
    .news-bloc__listitem {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: 0 -1%;
    }
    .bloc-news {
        img {
            border-radius: 3%;
        }
        .bloc-news__item {
            border-radius: 3%;
            flex-basis: 31%;
            margin: 0 1%;
            margin-bottom: 35px;
            padding-bottom: 20px;
            &.bloc-news__item--focus {
                flex-basis: 100%;
                border-radius: 12px;
                padding-bottom: 0;
                img {
                    margin-bottom: 0;
                }
            }
        }
        .bloc-news__title {
            margin-right: 20px;
            margin-left: 20px;
            margin-bottom: 20px;
        }
    }
    .bloc-news__wrapper {
        padding-top: 40%;
    }
    .bloc-news__picture + .bloc-news__wrapper  {
         padding-top: 0;
    }
    .bloc-news__item--focus {
        min-height: 345px;
        background-color: $color-4--1;
        flex-basis: 100%;
        padding: 0;
        .category {
            font-size: 1.7em;
            text-align: left;
        }
        .bloc-news__title {
            font-weight: $bold;
            font-size: 3.6em;
            margin: 0 0;
            text-align: left;
        }
        .bloc-news__infos {
            font-weight: $light;
            font-family: $typo-1;
            font-size: 2em;
            color: $color-3--5;
            margin-bottom: 10px;
        }
        .bloc-news__content {
            font-family: $typo-2;
            font-weight: $light;
            font-size: 1.5em;
            color: $color-white;
        }
        .bloc-news__wrapper {
            text-align: left;
            max-width: 400px;
            right: 0;
            top: 50%;
            position: absolute;
            transform: translateY(-50%);
            margin-right: 20px;
            min-width: 35%;
        }
        .bloc-news__infos {
            color: $color-white;
        }
        .bloc-news__content {
            color: $color-white;
        }
        .bloc-news__picture {
            display: inline-block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -8px;
        }
    }
    .link-view {
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
    }
    .button-2 {
        height: 65px;
        width: 65px;
        transform: rotate(45deg) translateX(-50%);
        border-radius: 20%;
        background-color: $color-4--3;
        box-shadow: 4px 3px 6px rgba(0, 0, 0, 0.5);
        left: 50%;
        position: absolute;
        top: -65px;
        &:hover, &:focus {
            background-color: $color-4--1;
        }
        &:before {
            transform: translateY(-50%) translateX(-50%) rotate(-45deg);
            content: '\e000';
        }
    }
}

//image on focus bloc
.news--image{
    clip-path: url('#poly-3');
    // to fix safari bug
    -webkit-clip-path: '';
    width: 660px;
    height: 395px;
    max-width: inherit;
    margin-bottom: 0;
}

.svg--focus {
    > svg {
        height: 0;
        width: 0;
    }
}

