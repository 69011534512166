// @name Heading single news
// @dependency .heading
.heading--single-news {
    .heading__picture {
        img {
            width: auto;
            max-width: 100%;
        }
    }
}
