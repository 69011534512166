.menu-main-1__sublevel {
    display: none;
    &[aria-hidden="false"] {
        display: block;
    }
}

//
.menu-main-1__item--no-action-in-desktop {
    .menu-main-1__sublevel {
        display: block;
    }
}

/* Styles */

// All level
.menu-main-1__wrapper {
    flex-basis: 65%;
    padding-top: 5px;
}

.menu-main-1 {
    @extend .wrapper-main;
    font-family: $typo-1;
    .wrapper-main {
        max-width: inherit;
    }
}

.menu-main-1__item {
    > .menu-main-1__header {
        a {
            display: block;
            &:hover {
                text-decoration: none;
            }
        }
    }
    > .menu-main-1__sublevel {

    }
    &.menu-main-1__item--current {

    }
}

.menu-main-1__closing-button {
    text-align: left;
    text-indent: -9999px;
    white-space: nowrap;
    position: absolute;
    top: .5rem;
    right: .5rem;
    width: 30px;
    height: 30px;
    background: transparent;
    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
        font-family: icons-default;
        content: "\e022";
        font-size: 1.3em;
        font-weight: $bold;
        text-indent: 0;
        line-height: 0;
        display: block;
        margin: 0;
    }
}

// Level 0 (displayed in tablet and smartphone)
.menu-main-1__listitems-0 {
    > li {
        > .menu-main-1__header {
            display: none;
        }
        > .menu-main-1__sublevel {
            display: block;
            > .menu-main-1__closing-button {
                display: none;
            }
        }
    }
}

// Level 1
.menu-main-1__listitems-1 {
    .background-square:after {
        background: $color-5--1;
        height: 17px;
        width: 17px;
        top: 10px;
        right: unset;
        left: 5px;
        z-index: 1;
        transition: background ease .3s;
    }
    > li {
        display: inline-block;
        vertical-align: middle;
        transition: background ease .3s;
        &:hover {
            background-color: $color-5--1;
            transition: background ease .3s;
            .background-square:after {
                background: $color-5--0;
                transition: background ease .3s;
            }
        }
        > .menu-main-1__header {
            > a {
                font-size: 1.6em;
                color: $color-3--7;
                text-transform: uppercase;
                padding: em(1, 2) em(2, 2);
                text-decoration: none;
                padding-left: 30px;
                &[aria-expanded="true"] {
                    &:before {
                        content: "\e024";
                        top: 52%;
                        left: 8px;
                    }
                }
                &:before {
                    font-family: "icons-default";
                    font-size: 1.2rem;
                    color: $color-3--7;
                    content: "\e027";
                    line-height: 1;
                    z-index: 2;
                    transform: translateY(-50%);
                    position: absolute;
                    top: 50%;
                    left: 9px;
                }
            }
        }
        > .menu-main-1__sublevel {
            display: block;
            position: absolute;
            z-index: 10;
            top: 110%;
            left: 0;
            right: 0;
            width: 100%;
            max-height: 0;
            visibility: hidden;
            overflow: hidden;
            transition: visibility ease .2s, max-height ease .2s;
            border-radius: 12px;
            background-color: $color-white;

            .menu-main-1__listitems-1__wrapper {
                //padding: 2em 0;
                transform: translateY(-110%);
                transition: transform ease-in .2s;
                height: 100%;
            }
            // level 1 Displayed
            &[aria-hidden="false"] {
                visibility: visible;
                max-height: 1000px;
                .menu-main-1__listitems-1__wrapper {
                    transform: translateY(0);
                    transition: transform ease-out .4s .2s;
                }
            }
            a, button, p {
                color: $color-3--7;
                &:focus {
                    outline-color: $color-3--7;
                }
            }
        }
    }
    .menu-main-1__item--current, .menu-main-1__item--active {
        background-color: $color-5--1;
        .background-square:after {
            background: $color-5--0;
        }
        .menu-main-1__header {
            > a {
                &:before {
                    font-family: "icons-default";
                    font-size: 1.2rem;
                    content: "\e027";
                    line-height: 1;
                    z-index: 2;

                }
            }
        }
    }
}

// Level 2
.menu-main-1__listitems-2 {
    width: 25vw;
    position: relative;
    height: auto;
    transition: height ease .3s;
    background: white;
    border-radius: 12px;
    padding-top: 17px;
    > li {
        display: block;
        border-bottom: 1px solid $color-white;
        border-radius: 8px;
        transition: background ease .3s;
        &:hover {
            background-color: $color-4--3;
            transition: background ease .3s;
            .menu-main-1__header > a:before {
                color: $color-5--1;
            }
        }
        // Level 2 and 3
        .menu-main-1__header {
            position: relative;
            .menu-main-1__action {
                position: absolute;
                top: 0;
                right: 20px;
                bottom: 0;
                text-align: left;
                text-indent: -9999px;
                white-space: nowrap;
                width: 3rem;
                background: transparent;
                overflow: hidden;
                flex-basis: 3rem;
                padding: 0 30px;
                &:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%);
                    font-family: "icons-default";
                    content: "\e027";
                    font-size: .9em;
                    font-weight: $bold;
                    text-indent: 0;
                    line-height: 0;
                    display: block;
                    margin: 0;
                    z-index: 2;
                }
                &:after {
                    background: $color-5--1;
                    height: 17px;
                    width: 17px;
                    top: 50%;
                    right: unset;
                    left: 20px;
                    content: '';
                    position: absolute;
                    z-index: 1;
                    transform: rotate(45deg) translateY(-36%) translateX(-35%);
                    border-radius: 20%;

                }
                &[aria-expanded="true"] {
                    &:before {

                    }
                }
            }
        }
        > .menu-main-1__header {
            a {
                text-transform: uppercase;
                font-size: 1.8em;
                font-weight: $normal;
                padding: 5px 65px 5px 30px;
                &:before {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 9px;
                }
            }
        }
        > .menu-main-1__sublevel {
            display: block;
            position: absolute;
            left: 96%;
            top: 0;
            width: 25.6vw;
            visibility: hidden;
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform ease .2s, visibility ease 0s .2s;
            background: $color-4--3;
            border-radius: 12px;

            &[aria-hidden="false"] {
                visibility: visible;
                transform: scaleX(1);
                transition: transform ease .4s, -webkit-transform ease .4s;
            }
        }

    }
    .menu-main-1__item--current, .menu-main-1__item--active {
        background-color: $color-4--3;
        .menu-main-1__header > a:before {
            color: $color-5--1;
        }
        .menu-main-1__header {
            > a {
                &:before {
                    content: "\e027";
                    position: absolute;
                    top: 50%;
                    left: 9px;
                    transform: translateY(-50%);
                }
            }
        }
    }
    .menu-main-1__closing-button {
        display: none;
    }
}

// Level 3
.menu-main-1__listitems-3 {
    padding: 1em;
    > li {
        margin: 0.8em 0;
        border-radius: 8px;
        transition: background ease .3s;
        &:hover {
            background-color: $color-4--2;
            transition: background ease .3s;
        }
        > .menu-main-1__header {
            a {
                text-transform: uppercase;
                font-size: 1.7em;
                font-weight: $normal;
                padding: 5px 65px 5px 30px;
            }
        }
        > .menu-main-1__sublevel {
            display: block;
            position: absolute;
            left: 96%;
            top: 0;
            width: 25.6vw;
            visibility: hidden;
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform ease .2s, visibility ease 0s .2s;
            background: $color-4--2;
            border-radius: 12px;

            &[aria-hidden="false"] {
                transition: transform ease .4s, visibility ease 0s 0s;
                visibility: visible;
                transform: scaleX(1);
            }
        }
    }
    .menu-main-1__item--current, .menu-main-1__item--active {
        background-color: $color-4--2;
        .menu-main-1__header > a:before {
            color: $color-5--1;
            content: '\e013';
            font-size: 1em;
        }
    }
    .menu-main-1__item {
        .menu-main-1__header {
            a {
                &:before {
                    content: '\e013';
                    font-size: 1em;
                    transform: translateY(-50%);
                }
            }
        }

    }
}

// Level 4
.menu-main-1__listitems-4 {
    padding: 1em;
    > li {
        margin: 0.8em 0;
        border-radius: 8px;
        transition: background ease .3s;
        &:hover {
            background-color: $color-4--1;
            transition: background ease .3s;
            a {
                color: $color-white;
            }
        }
        > .menu-main-1__header {
            a {
                font-size: 1.5em;
                padding: 5px 65px 5px 30px;
            }
        }
        > .menu-main-1__sublevel {
            display: block;
            position: absolute;
            left: 96%;
            top: 0;
            width: 25.6vw;
            visibility: hidden;
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform ease .2s, visibility ease 0s .2s;
            background: $color-4--1;
            border-radius: 12px;
            a {
                color: $color-white;
            }
            &[aria-hidden="false"] {
                transition: transform ease .4s, visibility ease 0s 0s;
                visibility: visible;
                transform: scaleX(1);
            }
        }
    }
    .menu-main-1__item--current, .menu-main-1__item--active {
        background-color: $color-4--1;
        .menu-main-1__header a {
            color: $color-white;
            padding-left: 30px;
        }
    }
}

// Level 5
.menu-main-1__listitems-5 {
    padding: 1em;
    padding-left: 3em;
    > li {
        margin: 0.8em 0;
        border-radius: 8px;
        > .menu-main-1__header {
            a {
                font-size: 1.5em;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

    }
    .menu-main-1__item {
        .menu-main-1__header {
            a {
                &:before {
                    color: $color-white;
                }
            }
        }

    }
}

.home {
    .menu-main-1__listitems-2 {
        width: 23.8vw;
    }
    .menu-main-1__listitems-2 > li > .menu-main-1__sublevel {
        width: 24.3vw;
    }
    .menu-main-1__listitems-3 > li > .menu-main-1__sublevel {
        width: 24.3vw;
    }
    .menu-main-1__listitems-4 > li > .menu-main-1__sublevel {
        width: 24.2vw;
    }

}
